import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Segment, Button, Image, TextArea, Icon } from "semantic-ui-react";
import "./App.scss";
import * as actions from "./actions/action.js";
import bag from "./images/bag.png";
import botteBrumale from "./images/bottebuona-brumale.jpg";
import duetigliRonco from "./images/duetigli-ronco.png";
import duetigliSangioveseMarche from "./images/duetigli-sangiovese-marche.png";
import duetigliSangiovese from "./images/duetigli-sangiovese.png";
import duetigliTrebbiano from "./images/duetigli-trebbiano.png";
import kwellP from "./images/kwell_product.png";
import illyClassico from "./images/illy_classico.png";
import laborest1 from "./images/laborest-tiobec_800.png";
import moschinoCaseMan from "./images/moschino_case_man.png";
import moschinoPortachiavi from "./images/moschino_portachiavi.png";
import moschinoPortachiavi2 from "./images/moschino_portachiavi_2.png";
import moschinoZaino from "./images/moschino_zaino.png";
import naturitaliaJingold from "./images/jingold.png";
import okitask from "./images/okitask_product.jpg";
import pearson from "./images/pearson.png";
import peroniBottle from "./images/peroni_bottiglia_peroni.jpg";
import peroniFormati1 from "./images/peroni_formati_1.jpg";
import peroniFormati2 from "./images/peroni_formati_2.jpg";
import peroniTerzoTempo from "./images/peroni_terzo_tempo.jpg";
import peroni35 from "./images/peroni3.5.png";
import peroniMinifrigo from "./images/peroni_minifrigo.png";
import product from "./images/product.png";
import romioModenesi from "./images/romio-modenesi.jpg";
import send from "./images/sendWhite.svg";
import tavernello from "./images/tavernello.jpg";
import tavernelloCastellino from "./images/tavernello-castellino.jpg";
import tiniTerre from "./images/tini-terre_forti.jpg";
import vergnanoArabica from "./images/caffe-arabica-250gr.jpg";
import wine from "./images/wine.png";
// import send from './images/send.svg'

function connectActions(dispatch) {
  return bindActionCreators(actions, dispatch);
}
function mapStateToProps(store) {
  return {
    keyboard: store.keyboard,
    chatHistory: store.chatHistory,
    chatStack: store.chatStack,
    typing: store.typing,
    buttons: store.buttons,
    linkNumber: store.linkNumber
  };
}

let globMsgUser = "";

class Chat extends Component {
  state = {
    msg: "",
    prescriptionId: null,
    serviceId: null,
    inputMethod: "buttons"
  };

  componentWillMount = () => {
    this.initChat();
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.caller !== nextProps.caller) {
      this.initChat();
    } else if (nextProps.chatStack.length > 0 && nextProps.typing === false) {
      this.props.popFromStack(nextProps.chatStack[0]);
    }
  };

  initChat() {
    document.body.style.backgroundColor = "#0d5f76";
    this.props.refreshState();
    switch(this.props.caller) {
      case 'restaurant':
        this.props.restaurantFlow(
          "Il ristorante xy ti dà il benvenuto.\r\n Teniamo molto all’opinione di chi ci ha scelti.\r\n Se ti va, rispondi a qualche veloce domanda.\r\n Non preoccuparti, ci vuole un attimo."
        );
        break;
      case 'hotel':
        this.props.hotelFlow(
          "L’Hotel xy ti dà il benvenuto.\r\n Teniamo molto all’opinione di chi ci ha scelti.\r\n Se ti va, rispondi a qualche veloce domanda.\r\n Non preoccuparti, ci vuole un attimo."
        );
        break;
      case 'fortehotel':
        this.props.hotelFlow(
          "L’Hotel De La Ville Roma ti dà il benvenuto.\r\n Teniamo molto all’opinione di chi ci ha scelti.\r\n Se ti va, rispondi a qualche veloce domanda.\r\n Non preoccuparti, ci vuole un attimo."
        );
        break;
      case 'orogel':
      case 'product':
        this.props.productFlow("Grazie per aver acquistato la nostra insalata ai 5 cereali.");
        break;
      case 'shop':
        this.props.shopFlow(
          "Ciao, grazie per il tuo acquisto presso il Centro commerciale “Le Befane” di Rimini.\r\nTeniamo molto alla tua opinione e ci farebbe piacere farti qualche veloce domanda."
        );
        break;
      case 'conad':
        this.props.shopFlow(
          "Ciao, grazie per il tuo acquisto presso il Centro commerciale Montefiore di Cesena.\r\nTeniamo molto alla tua opinione e ci farebbe piacere farti qualche veloce domanda."
        );
        break;
      case 'crai':
        this.props.shopFlow(
          "Ciao, grazie per il tuo acquisto presso il tuo Supermercato Crai di fiducia.\r\nTeniamo molto alla tua opinione e ci farebbe piacere farti qualche veloce domanda."
        );
        break;
      case 'hera':
        this.props.heraFlow(
          "Hera ti dà il benvenuto.\r\nLa tua opinione è importante per noi.\r\nHai scelto l’area di smaltimento di Via Riccardo Brusi a Cesena."
        );
        break;
      case 'illy':
        this.props.illyFlow("Grazie per aver acquistato Caffè Macinato Moka tostato CLASSICO.");
        break;
      case 'wine':
        this.props.wineFlow("Buongiorno,\r\ngrazie per aver acquistato il nostro Sangiovese Tauleto 2012.");
        break;
      case 'pearson':
        this.props.pearsonFlow(
          "Gentile insegnante, desideriamo aiutarla nel processo di valutazione del nostro testo"
        );
        break;
      case 'famila':
        this.props.shopFlow(
          "Ciao, grazie per il tuo acquisto presso il Famila Superstore di Cesena.\r\nTeniamo molto alla tua opinione e ci farebbe piacere farti qualche veloce domanda."
        );
        break;
      case 'gabs':
        this.props.gabsFlow(
          "Gentile cliente, desideriamo ringraziarla per aver acquistato la nostra Sacca trasformabile in pelle multicolore 'scorpione'. Numero di serie XXYY12345. Prodotta nella sede di Scandicci"
        );
        break;
      case 'esselunga':
        this.props.esselungaFlow("Ti diamo il benvenuto.");
        break;
      case 'kwell':
        this.props.kwellFlow("Ciao,\r\nKwell ti dà il benvenuto.");
        document.body.style.backgroundColor = "#333"; // set body color black when is a kwell demo
        break;
      case 'dompe':
        this.props.okitaskFlow("Gentile cliente la ringraziamo per averci accordato la sua fiducia acquistando Okitask in bustine");
        break;
      case 'tavernello':
        this.props.tavernelloFlow("Gentile cliente la ringraziamo per averci accordato la sua fiducia acquistando il nostro Tavernello.");
        break;
      case 'peroni':
        this.props.peroniFlow("Grazie per aver acquistato birra Peroni.");
        break;
      case 'peronichallenge':
        this.props.peroniChallengeFlow("<IMG1>");
        break;
      case 'terranova':
        this.props.terranovaFlow("Ciao, grazie per il tuo acquisto nel punto vendita Terranova del Centro Commerciale le Befane di Rimini.");
        break;
      case 'vergnano':
        this.props.vergnanoFlow("Grazie per aver acquistato caffè Vergnano.");
        break;
      case 'duetigli':
        this.props.duetigliFlow("Gentile cliente la ringraziamo per averci accordato la sua fiducia acquistando il nostro Vino Rosso in brick Sancrispino.");
        break;
      case 'moschino':
        this.props.moschinoFlow("Moschino ti dà il benvenuto.");
        break;
      case 'naturitalia':
        this.props.naturitaliaFlow("Grazie per aver acquistato Jingold, il Kiwi giallo dolce e nutriente.");
        break;
      case 'tannico':
        this.props.tannicoFlow("Tannico ti dà il benvenuto!");
        break;
      case 'uriach':
        this.props.uriachFlow("Grazie per aver acquistato Tiobec® 800");
        break;
      default:
        break;
    }
  }

  componentWillUnmount = () => {
    document.body.style.backgroundColor = "#0d5f76";
  };

  componentDidMount() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.documentElement.style.setProperty("--txth", `${60}px`);

    var body = document.body,
      html = document.documentElement;
    var objDiv = document.getElementById("chatDiv");
    var height = Math.max(
      body.scrollHeight,
      body.clientHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    objDiv.scrollTop = height;
    window.scrollTo(0, height);
  }

  componentDidUpdate(prevProps) {
    if (this.state.msg === "") this.scrollBottom();
  }

  handleTextChange = (e, { value }) => {
    this.setState({ msg: value });
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.sendMsg();
    }
  };

  sendMsg = () => {
    if (/\S/.test(this.state.msg)) {
      var cleanMsg = this.state.msg.replace(/\s{2,}/g, " ").trim();
      this.props.pushClientRequest(cleanMsg, this.pushResponse);
      this.inputFocusOn(false);
      this.setState({ msg: "" });
    }
  };

  sendMsgWithBtn = msg => {
    if (/\S/.test(msg)) {
      this.props.clearButtons();
      var cleanMsg = msg.replace(/\s{2,}/g, " ").trim();
      this.props.pushClientRequest(cleanMsg, this.pushResponse);
      this.setState({ msg: "" });
    }
  };

  pushResponse = msg => {
    this.scrollBottom();
    this.switchInput("reset");
    switch(this.props.caller) {
      case 'restaurant':
        this.props.restaurantFlow(msg, this.scrollBottom);
        break;
      case 'hotel':
      case 'fortehotel':
        this.props.hotelFlow(msg, this.scrollBottom);
        break;
      case 'orogel':
      case 'product':
        this.props.productFlow(msg, this.scrollBottom);
        break;
      case 'shop':
      case 'conad':
      case 'crai':
      case 'famila':
        this.props.shopFlow(msg, this.scrollBottom);
        break;
      case 'hera':
        this.props.heraFlow(msg, this.scrollBottom);
        break;
      case 'wine':
        this.props.wineFlow(msg, this.scrollBottom);
        break;
      case 'pearson':
        this.props.pearsonFlow(msg, this.scrollBottom);
        break;
      case 'gabs':
        this.props.gabsFlow(msg, this.scrollBottom);
        break;
      case 'illy':
        this.props.illyFlow(msg, this.scrollBottom);
        break;
      case 'esselunga':
        this.props.esselungaFlow(msg, this.scrollBottom);
        break;
      case 'kwell':
        this.props.kwellFlow(msg, this.scrollBottom);
        break;
      case 'dompe':
        this.props.okitaskFlow(msg, this.scrollBottom);
        break;
      case 'peroni':
        this.props.peroniFlow(msg, this.scrollBottom);
        break;
      case 'peronichallenge':
        this.props.peroniChallengeFlow(msg, this.scrollBottom);
        break;
      case 'tavernello':
        this.props.tavernelloFlow(msg, this.scrollBottom);
        break;
      case 'terranova':
        this.props.terranovaFlow(msg, this.scrollBottom);
        break;
      case 'vergnano':
        this.props.vergnanoFlow(msg, this.scrollBottom);
        break;
      case 'duetigli':
        this.props.duetigliFlow(msg, this.scrollBottom);
        break;
      case 'moschino':
        this.props.moschinoFlow(msg, this.scrollBottom);
        break;
      case 'naturitalia':
        this.props.naturitaliaFlow(msg, this.scrollBottom);
        break;
      case 'tannico':
        this.props.tannicoFlow(msg, this.scrollBottom);
        break;
      case 'uriach':
        this.props.uriachFlow(msg, this.scrollBottom);
        break;
      default:
        break;
    }
  };

  scrollBottom = () => {
    var objDiv = document.getElementById("chatDiv");
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight + 100;
    }
    // document.getElementById("input-msg-box").focus();
  };

  replaceNewLine = text => {
    var textArray = text.split('<BR>');
    if (textArray.length > 1) {
      return (textArray.map((el, index) => {
        return <React.Fragment key={index}>{el}{index!==(textArray.length-1) && <br/>}</React.Fragment>
      }));
    } else {
      return textArray[0];
    }
  }

  renderImg = (caller, phrase) => {
    switch(caller) {
      case 'product':
        return <Image className="questionImage" size="small" src={product} />;
      case 'wine':
        return <Image className="questionImage" size="tiny" src={wine} />;
      case 'pearson':
        return <Image className="questionImage" size="small" src={pearson} />;
      case 'gabs':
        if (phrase.response === "<IMG1>") {
          return <Image className="questionImage" size="small" src={bag} />
        }
        break;
      case 'kwell':
        return <Image className="questionImage" size="small" src={kwellP} />;
      case 'dompe':
        return <Image className="questionImage" size="small" src={okitask} />;
      case 'moschino':
        if (phrase.response === "<IMG1>") {
          return <Image className="questionImage" size="small" src={moschinoZaino} />;
        } else if (phrase.response === "<IMG2>") {
          return <Image className="questionImage" size="small" src={moschinoCaseMan} />;
        } else if (phrase.response === "<IMG3>") {
          return <Image className="questionImage" size="small" src={moschinoPortachiavi} />;
        } else if (phrase.response === "<IMG4>") {
          return <Image className="questionImage" size="small" src={moschinoPortachiavi2} />;
        }
        break;
      case 'naturitalia':
        if (phrase.response === "<IMG1>") {
          return <Image className="questionImage" size="small" src={naturitaliaJingold} />;
        }
        break;
      case 'peroni':
        if (phrase.response === "<IMG1>") {
          return <Image className="questionImage" style={{maxWidth:'150px', maxHeight:'200px'}} src={peroniBottle} />;
        } else if (phrase.response === "<IMG2>") {
          return <Image className="questionImage" size="small" src={peroniFormati1} />;
        } else if (phrase.response === "<IMG3>") {
          return <Image className="questionImage" size="small" src={peroniFormati2} />;
        }
        break;
      case 'peronichallenge':
        if (phrase.response === "<IMG1>") {
          return <Image className="questionImage" size="small" src={peroniTerzoTempo} />;
        } else if (phrase.response === "<IMG2>") {
          return <Image className="questionImage" size="small" src={peroni35} />;
        } else if (phrase.response === "<IMG3>") {
          return <Image className="questionImage" size="small" src={peroniMinifrigo} />;
        }
        break;
      case 'tavernello':
        if (phrase.response === "<IMG1>") {
          return <Image className="questionImage" size="small" src={tavernello} />;
        } else if (phrase.response === "<IMG2>") {
          return <Image className="questionImage" size="small" src={tavernelloCastellino} />;
        } else if (phrase.response === "<IMG3>") {
          return <Image className="questionImage" size="small" src={tiniTerre} />;
        } else if (phrase.response === "<IMG4>") {
          return <Image className="questionImage" size="small" src={romioModenesi} />;
        } else if (phrase.response === "<IMG5>") {
          return <Image className="questionImage" size="small" src={botteBrumale} />;
        }
        break;
      case 'vergnano':
        if (phrase.response === "<IMG1>") {
          return <Image className="questionImage" size="small" src={vergnanoArabica} />;
        }
        break;
      case 'illy':
        if (phrase.response === "<IMG1>") {
          return <Image className="questionImage" size="small" src={illyClassico} />;
        }
        break;
      case 'duetigli':
        if (phrase.response === "<IMG1>") {
          return <Image className="questionImage" size="tiny" src={duetigliRonco} />;
        } else if (phrase.response === "<IMG2>") {
          return <Image className="questionImage" size="tiny" src={duetigliSangioveseMarche} />;
        } else if (phrase.response === "<IMG3>") {
          return <Image className="questionImage" size="tiny" src={duetigliSangiovese} />;
        } else if (phrase.response === "<IMG4>") {
          return <Image className="questionImage" size="tiny" src={duetigliTrebbiano} />;
        }
        break;
      case 'uriach':
        return <Image className="questionImage" size="small" src={laborest1} />;
      default:
        break;
    }
  }

  renderLink = (caller, el) => {
    switch (caller) {
      case 'illy':
        let linkIdx = el.split(/<\d>/);
        let linkN = '';
        let linkUrl = '';
        let linkText = el;
        if (linkIdx.length===2) {
          linkText = linkIdx[1];
          linkN = el.match(/\<(\d)\>/);
          linkN = linkN ? linkN[1] : '';
        }
        switch(linkN) {
          case '1':
            linkUrl = 'https://www.illy.com/it-it/shop/caffe/caffe-macinato/moka-tostato-classico-250g/7189ME.html';
          break;
          case '2':
            linkUrl = 'https://www.illy.com/it-it/shop/caffe/caffe-macinato/moka-tostato-intenso-250g/7191ME.html';
          break;
          case '3':
            linkUrl = 'https://www.illy.com/it-it/shop/caffe/caffe-macinato/moka-tostato-forte-250g/7192ME.html';
          break;
          case '4':
            linkUrl = 'https://www.illy.com/it-it/shop/gourmet-idee-regalo/offerte-macchine-caffe-confezioni-regalo-caffe';
          break;
          case '5':
            linkUrl = 'https://www.illy.com/it-it/shop/caffe/caffe-macinato/moka-tostato-classico-250g/7189ME.html?cgid=33504';
          break;
          default:
          break;
        }
        return (
          <a href={linkUrl} target="_blank" rel="noreferrer noopener">
            {linkText}
          </a>
        );
      case 'moschino':
        return (
          <a href={"https://www.moschino.com/it_it/moschino/uomo/accessori/portafogli-e-accessori.html?p=1&listFor=4"} target="_blank" rel="noreferrer noopener">
            {el}
          </a>
        );
      case 'naturitalia':
        return (
          <a href={"https://www.lab51.it"} target="_blank" rel="noreferrer noopener">
            {el}
          </a>
        );
      case 'vergnano':
        return (
          <a href={this.props.linkNumber === 1 ? "https://www.caffevergnano.com/shop/capsule-compatibili-nespresso/pack-100-espresso1882-cremoso" : "https://www.caffevergnano.com/shop/"} target="_blank" rel="noreferrer noopener">
            {el}
          </a>
        );
      case 'wine':
        return (
          <a href={"https://umbertocesari.com/vini/lista-vini/"} target="_blank" rel="noreferrer noopener">
            {el}
          </a>
        );
      default:
        return (
          <a href={el} target="_blank" rel="noreferrer noopener">
            {el}
          </a>
        );
    }
  }

  replaceTag = phrase => {
    if (phrase.user === "bot") {
      var counter = 0;
      var textArray = phrase.response.split("<LINK>");
      if (
        phrase.response === "<IMG>" ||
        phrase.response === "<IMG1>" ||
        phrase.response === "<IMG2>" ||
        phrase.response === "<IMG3>" ||
        phrase.response === "<IMG4>" ||
        phrase.response === "<IMG5>"
      ) {
        return (
          <div>
            {this.renderImg(this.props.caller, phrase)}
          </div>
        );
      }
      if (phrase.response === "<VIDEO>") {
        return (
          <div>
            <iframe title="gabs" width="100%" height="auto" src="https://www.youtube.com/embed/8nVqNUS-POs" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
            </iframe>
            <p>
              <span>
                <a href={"https://www.youtube.com/watch?v=8nVqNUS-POs"} target="_blank" rel="noreferrer noopener">
                  https://www.youtube.com/watch?v=8nVqNUS-POs
                </a>
              </span>
            </p>
          </div>
        );
      } else if (textArray.length > 2) {
        return (
          <p>
            {textArray.map((el, index) => {
              if (counter++ % 2 === 0) {
                return <span key={index}>{this.replaceNewLine(el)}</span>;
              } else {
                return <span key={index}> {this.renderLink(this.props.caller, el)}</span>
              }
            })}
          </p>
        );
      } else {
        return <p>{this.replaceNewLine(textArray[0])}</p>;
      }
    } else {
      return <p>{phrase.response}</p>;
    }
  };

  loadBubbles = history => {
    // var user = "";
    var topSpace = 0;
    var conv = history.map((phrase, index) => {
      if (index > 0) topSpace = globMsgUser !== phrase.user ? 15 : 5;
      globMsgUser = phrase.user;
      return (
        <div key={index}>
          <div
            className={"chatMsg " + (phrase.user === "bot" ? "received" : "sent")}
            style={{ marginTop: topSpace + "px", ...(phrase.background ? {backgroundColor:phrase.background}:{}) }}
          >
            {this.replaceTag(phrase)}
          </div>
        </div>
      );
    });
    return conv;
  };

  inputFocusOn = bool => {
    var txth = bool ? 96 : 60;
    document.documentElement.style.setProperty("--txth", `${txth}px`);
    this.setState({ inputFocus: bool });
  };

  switchInput = mode => {
    var method = this.state.inputMethod;
    /*
     ** if is not a switchable demo set always buttons, then if there aren't buttons
     ** textArea is enabled anyway
     */
    if (!this.props.keyboard) {
      method = "buttons";
    } else {
      /*
       ** mode needed to know if want to reset to buttons mode after button click ('reset') or switch from one to other ('default')
       ** if mode switched to buttons but there aren't buttons, chat displays automatically textare also if is in buttons mode
       */
      if (mode === "default")
        method = this.state.inputMethod === "buttons" ? "text" : "buttons";
      else method = "buttons";
    }
    this.setState({ inputMethod: method });
  };

  render() {
    window.addEventListener("resize", () => {
      // listen to the resize event
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
    return (
      <Segment.Group id="chatWrapper">
        <Segment basic textAlign="left" id="chatSegment">
          <Segment
            className="conversation"
            style={{ boxShadow: "none", border: "none" }}
          >
            <div id="chatDiv" className="conversation-container">
              {this.props.chatHistory &&
                this.loadBubbles(this.props.chatHistory)}
              {this.props.chatStack.length > 0 && this.props.typing && (
                <div>
                  <div
                    className="chatMsg received"
                    style={{
                      marginTop:
                        globMsgUser === ""
                          ? "0px"
                          : globMsgUser === "client"
                          ? "15px"
                          : "5px"
                    }}
                  >
                    <div className="typing-indicator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Segment>
        </Segment>
        <div id="footer">
          {this.props.buttons.length > 0 &&
          this.state.inputMethod === "buttons" ? (
            <div
              id="buttonsDiv"
              style={{ padding: this.props.keyboard ? "10px 15px" : "10px" }}
            >
              {this.props.keyboard && (
                <div
                  style={{ height: "100%", float: "left", cursor: "pointer" }}
                  onClick={() => this.switchInput("default")}
                >
                  <Icon
                    circular
                    name="pencil"
                    size="large"
                    disabled={this.props.disable}
                  />
                </div>
              )}
              <ButtonsSection
                buttons={this.props.buttons}
                answer={this.sendMsgWithBtn}
              />
            </div>
          ) : (
            <TextAreaComponent
              inputFocus={this.state.inputFocus}
              inputFocusOn={this.inputFocusOn}
              msg={this.state.msg}
              sendMsg={this.sendMsg}
              switchInput={this.switchInput}
              handleKeyPress={this.handleKeyPress}
              handleTextChange={this.handleTextChange}
              disable={this.props.chatStack.length > 0 || this.props.typing}
            />
          )}
        </div>
      </Segment.Group>
    );
  }
}

class TextAreaSection extends Component {
  state = {};

  componentWillReceiveProps = nextProps => {
    if (this.props.inputFocus === false && nextProps.inputFocus === true) {
      var objDiv = document.getElementById("msg-box-active");
      if (objDiv) {
        setTimeout(function() {
          objDiv.focus();
        }, 200);
      }
    }
  };

  render() {
    let keyboardSwitch = this.props.keyboard && this.props.buttons.length > 0;
    return (
      <div
        id="buttonsDiv"
        style={{ padding: "10px 15px", textAlign: "left" }}
        onBlur={() => setTimeout(() => this.props.inputFocusOn(false), 200)}
      >
        {keyboardSwitch && (
          <div
            style={{ height: "100%", float: "left", cursor: "pointer" }}
            onClick={() => this.props.switchInput("default")}
          >
            <Icon
              circular
              name="chevron left"
              size="large"
              style={{ marginTop: this.props.inputFocus ? "50%" : 0 }}
              disabled={this.props.disable}
            />
          </div>
        )}
        <div
          style={{
            height: "100%",
            float: "left",
            width: keyboardSwitch ? "calc(100% - 90px)" : "calc(100% - 60px)",
            paddingLeft: "7px"
          }}
        >
          <TextArea
            id="msg-box-active"
            className="chatTextarea"
            placeholder="Il tuo messaggio..."
            value={this.props.msg}
            disabled={this.props.disable}
            onChange={this.props.handleTextChange}
            onFocus={() => this.props.inputFocusOn(true)}
            onKeyPress={this.props.handleKeyPress}
          />
        </div>
        <div
          style={{ height: "100%", float: "right", cursor: "pointer" }}
          onClick={this.props.sendMsg}
          onFocus={() => this.props.inputFocusOn(true)}
        >
          <Image
            src={send}
            id="sendImage"
            style={{ marginTop: this.props.inputFocus ? "50%" : 0 }}
            disabled={this.props.disable}
          />
        </div>
      </div>
    );
  }
}

class ButtonsSection extends Component {
  state = {};

  componentDidMount() {
    // var objDiv = document.getElementById("buttons-section");
    // if(objDiv){
    //   objDiv.scrollLeft = objDiv.scrollWidth;
    // }
  }

  render() {
    return (
      <div id="buttons-section" className="only-buttons-div">
        {this.props.buttons.map((txt, index) => (
          <div
            key={index}
            style={{
              width: 100 / this.props.buttons.length + "%",
              minWidth: "min-content",
              display: "inline-table",
              padding: "0 5px",
              textAlign: "center"
            }}
          >
            <Button
              className="style-button selection-button"
              onClick={() => this.props.answer(txt)}
            >
              {txt}
            </Button>
          </div>
        ))}
      </div>
    );
  }
}

const TextAreaComponent = connect(mapStateToProps, null)(TextAreaSection);

export default connect(mapStateToProps, connectActions)(Chat);
