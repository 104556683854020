import React, { Component } from 'react';
import { withRouter }  from 'react-router-dom'
import { Segment, Input, Button } from 'semantic-ui-react'
import './App.scss';

class QrPage extends Component {

	state = {
		url:'',
		generatedUrl:'',
		img:'',
		tempSize:"",
		size: 512
	}

	handleChange = (e, { name, value }) => {
		this.setState({[name]:value});
	}

	handleChangeInt = (e, { name, value }) => {
		const intRegex = /^(([1-9]+[0-9]*))$/;
		if (intRegex.test(value) || value==="") {
			this.setState({[name]:value});
		}
	}

	generateQrCode = () => {
		this.setState({generatedUrl:this.state.url, size:this.state.tempSize ? this.state.tempSize : 512});
	}

	downloadImg = () => {
		var canvas = document.getElementById("mcanvas");
		var image = canvas.toDataURL("image/jpeg");

		var link = document.createElement('a');
		link.download = this.state.generatedUrl+'-'+this.state.size+".jpg";
		link.href = image;
		link.target = "_blank";
		link.click();
	}

	render() {
		var QRCode = require('qrcode.react');
		var size = this.state.size ? parseInt(this.state.size) : 512;
	    return (
	    	<Segment>
	    		<div>
	    			<Input 
	    				name='url' 
	    				action={<Button onClick={this.generateQrCode}>Genera</Button>}
	    				value={this.state.url}
	    				style={{marginRight:'10px', marginBottom:'10px', fontSize:'16px'}}
	    				onChange={this.handleChange}
	    			/>
	    			<Input 
	    				name='tempSize'
	    				placeholder='Dimensioni'
	    				value={this.state.tempSize}
	    				style={{fontSize:'16px'}}
	    				onChange={this.handleChangeInt}
	    			/>
	    		</div>
				<br/>
	    		{this.state.generatedUrl &&
		    		<div style={{width:'100%'}}>
	    				<QRCode id='mcanvas' style={{maxWidth:'100%', height:'auto'}} value={this.state.generatedUrl} size={size}/>
	    				<br/>
    					<Button onClick={this.downloadImg}>Download</Button>
		    		</div>
		    	}
	    	</Segment>
	    );
	}
}

export default withRouter(QrPage);