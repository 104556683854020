import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Container} from 'semantic-ui-react'
import CustomHeader from './Header';
import AdminTemplate from './AdminTemplate';
import ReviewsPage from './ReviewsPage';
import Dashboard from './Dashboard';
import QrPage from './QrPage';
import Home from './Home';
import Chat from './Chat';
import './App.scss';

export default class App extends Component {
    render() {
      return (
        <Router>
          <Switch>
            <Route path='/dashboard'>
              <Switch>
                  <Route exact path='/dashboard' >
                    <AdminTemplate page='dashboard'>
                      <Dashboard/>
                    </AdminTemplate>
                  </Route>
                  <Route exact path='/dashboard/reviews' >
                    <AdminTemplate page='reviews'>
                      <ReviewsPage/>
                    </AdminTemplate>
                  </Route>
                  <Route exact path='/dashboard/qr-generator' >
                    <AdminTemplate page='qr-generator'>
                      <QrPage/>
                    </AdminTemplate>
                  </Route>
              </Switch>
            </Route>
            <Route path='/poll/:pollName'
              render={(props)=>
                <Container id="rootContainer" className={"wrapper_"+props.match.params.pollName}>
                 <CustomHeader title={props.match.params.pollName}/>
                 <Chat caller={props.match.params.pollName}/>
                </Container>
              }
            />
            <Route path='/' >
              <Container id="rootContainer">
                <CustomHeader title="HOME"/>
                <Home/>
              </Container>
            </Route>
          </Switch>
        </Router>
      );
    }
}
