import React, { Component } from 'react';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

export default class CustomRadar extends Component {
    render () {
    return (
        <ResponsiveContainer width="100%" height={400}>
            <RadarChart cx="50%" cy="50%" outerRadius="70%" data={this.props.data}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis/>
                <Radar name="over 30" dataKey="A" stroke="#7900ff" fill="#7900ff" fillOpacity={0.6}/>
                <Radar name="under 30" dataKey="B" stroke="#ff8b00" fill="#ff8b00" fillOpacity={0.6}/>
                <Legend/>
            </RadarChart>
        </ResponsiveContainer>
    )
  }
}