import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Segment, Statistic, Grid, Progress, Dropdown, Comment } from 'semantic-ui-react'
import CustomPie from './CustomPie';
import CustomRadar from './CustomRadar';
import CustomBars from './CustomBars';
import CustomArea from './CustomArea';
import quotesImg from './images/quotes.png'
import './App.scss';

const options = [
  { key: 1, text: 'Questionario 1', value: 1 },
  { key: 2, text: 'Questionario 2', value: 2 },
  { key: 3, text: 'Questionario 3', value: 3 },
]

function mapStateToProps (store) {
  return {
    reviews: store.reviewsData,
  };
}

class Dashboard extends Component {

	state = {
		review: 1,
		reviewData: []
	}

	componentWillMount = () => {
		this.setState({reviewData: this.props.reviews.find(rev => {return rev.id === this.state.review})});
	}

	changeReview = (e, {value}) => {
		var revData = this.props.reviews.find(rev => {return rev.id === value});
		this.setState({review: value, reviewData: revData});
	}

	render() {
	    return (
	    	<Grid stackable className='dashboard'>
	    		<Grid.Row columns='equal' style={{paddingBottom:0}}>
					<Grid.Column>
						<Segment style={{paddingTop:'8px', paddingBottom:'8px'}}>
							<Dropdown placeholder='State' search selection value={this.state.review} options={options} onChange={this.changeReview}/>
						</Segment>
					</Grid.Column>
				</Grid.Row>
	    		<Grid.Row columns='equal'>
					<Grid.Column>
						<Segment>
							<Statistic.Group size='small'>
								{this.state.reviewData && this.state.reviewData.header.map((rev, index) => (
									<Statistic key={index}>
										<Statistic.Value>{rev.value}</Statistic.Value>
										<Statistic.Label>{rev.name}</Statistic.Label>
									</Statistic>
								))}
							</Statistic.Group>
						</Segment>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Grid columns='equal'>
							<Grid.Row stretched>
								<Grid.Column className='left-col width100'>
									<Segment>
										<h2>Grafico annuale</h2>
										<CustomArea data={this.state.reviewData && this.state.reviewData.customArea}/>
									</Segment>
								</Grid.Column>
								<Grid.Column className='right-col width100'>
									<Segment>
										<h2>Percentuali voti</h2>
										{this.state.reviewData && this.state.reviewData.percVotes.map((rev, index) => (
											<Progress key={index} percent={rev.value} id="progressBar" progress color='purple' label={rev.label}/>
										))}
									</Segment>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns='equal'>
					<Grid.Column>
						<Segment style={{width:'100%', textAlign:'center'}}>
							<h2>Grafici valutazioni</h2>
							{this.state.reviewData && this.state.reviewData.customPie.map((rev, index) => (
								<div key={index} className='inline-div' style={{border:'1px solid lightgrey', borderRadius:'4px'}}>
									<CustomPie data={rev.data} pieName={rev.name}/>
								</div>
							))}
						</Segment>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns='equal'>
					<Grid.Column style={{textAlign:'center'}}>
						<Segment>
							<Grid columns='equal'>
								<Grid.Row stretched>
									{this.state.reviewData && this.state.reviewData.customRadar &&
										<Grid.Column className='width100'>
											<h2>Dispositivi</h2>
											<CustomRadar data={this.state.reviewData.customRadar}/>
										</Grid.Column>
									}
									{this.state.reviewData && this.state.reviewData.customBar &&
										<Grid.Column className='width100'>
											<h2>Distribuzione settimanale</h2>
											<CustomBars data={this.state.reviewData.customBar}/>
										</Grid.Column>
									}
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Column>
				</Grid.Row>
				{this.state.reviewData && this.state.reviewData.comments.length > 0 &&
					<Grid.Row stretched>
						<Grid.Column>
							<Segment>
								<h2 style={{textAlign:"center"}}>Commenti recenti</h2>
								<Comment.Group style={{maxWidth:"none"}}>
								{this.state.reviewData.comments.map((cmt,index) =>
									<Comment key={index}>
										<Comment.Avatar src={quotesImg} style={{width:"20px"}}/>
									    	<Comment.Content>
									        	<Comment.Author as ="span">{cmt.header}</Comment.Author>
									        	<Comment.Metadata>
									          		<div>({cmt.date})</div>
									        	</Comment.Metadata>
									        	<Comment.Text>{cmt.text}</Comment.Text>
									      </Comment.Content>
								    </Comment>
								)}
						  		</Comment.Group>
						  	</Segment>
						</Grid.Column>
					</Grid.Row>
				}
	    	</Grid>
	    );
	}
}

export default connect(mapStateToProps, null)(Dashboard);
