import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell, ResponsiveContainer } from 'recharts';

const colors = ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"];
const getPath = (x, y, width, height) => {
  return `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + 2 * width / 3},${y + height} ${x + width}, ${y + height}
          Z`;
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill}/>;
};

export default class CustomBars extends Component {
  render () {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={this.props.data}
              margin={{top: 20, right: 30, left: 20, bottom: 5}}>
         <XAxis dataKey="name"/>
         <YAxis/>
         <CartesianGrid strokeDasharray="3 3"/>
         <Bar dataKey="week" fill="#8884d8" shape={<TriangleBar/>} label={{ position: 'top' }}>
           {
              this.props.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]}/>
              ))
            }
         </Bar>
        </BarChart>
      </ResponsiveContainer>
    )
  }
}