import React, { Component } from 'react';
import { PieChart, Pie, Sector, Label,ResponsiveContainer } from 'recharts';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 25) * cos;
  const my = cy + (outerRadius + 25) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 10;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
     {/* <text x={cx} y={cy} dy={8} textAnchor="middle">{payload.name}</text>*/}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.filler}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={payload.filler}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={payload.filler} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={payload.filler} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 5} y={ey} textAnchor={textAnchor} fill="#333">{payload.value?payload.value:value}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 5} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${(percent).toFixed(2)}%`}
      </text>
    </g>
  );
};


export default class CustomPie extends Component {
    state = {
        activeIndex: null,
    }

    onPieEnter = (data, index) => {
        this.setState({
          activeIndex: index,
        });
    }

    render() {
      return (
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
              <Pie
                  activeIndex={this.state.activeIndex}
                  activeShape={renderActiveShape} 
                  data={this.props.data}
                  dataKey="percent"
                  cx="50%" 
                  cy="50%"
                  innerRadius={50}
                  outerRadius={70} 
                  fill="#3caea3"
                  onMouseEnter={this.onPieEnter}
                  onClick={this.onPieEnter}
                  onMouseLeave={()=>this.setState({activeIndex:null})}
              >
              <Label value={this.props.pieName} offset={0} position="center" />
              </Pie>
          </PieChart>
        </ResponsiveContainer>
      )
    }
}