import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Image, Header } from "semantic-ui-react";
import "./App.scss";
import conadLogo from "./images/cnd.jpg";
import craiLogo from "./images/cra.jpg";
import esselungaLogo from "./images/esselunga.png";
import familaLogo from "./images/famila-logo.png";
import gabsLogo from "./images/gabs.jpg";
import illyLogo from "./images/illy_logo.png";
import kwellLogo from "./images/kwell.png";
import heraLogo from "./images/hera.jpg";
import laborestLogo from "./images/laborest-logo.png";
import logo from "./images/logo.jpg";
import moschinoLogo from "./images/moschino_logo.jpg";
import naturitaliaLogo from "./images/logo-jingold.png";
import okitaskLogo from "./images/okitask.png";
import orogelLogo from "./images/orgl.jpg";
import peroniLogo from "./images/peroni-logo.png";
import tannicoLogo from "./images/tannico_logo.png";
import tavernelloLogo from "./images/tavernello-logo.png";
import terranovaLogo from "./images/terranova-logo.jpg";
import vergnanoLogo from "./images/vergnano-logo.jpg";
import duetigliLogo from "./images/terre_cevico.png";

class CustomHeader extends Component {

  renderLogo = (title) => {
    switch(title) {
      case 'fortehotel':
        return <Header>Rocco Forte Hotels</Header>;
      case 'conad':
        return <Image style={{ maxHeight: "40px" }} src={conadLogo} />;
      case 'crai':
        return <Image style={{ maxHeight: "40px" }} src={craiLogo} />;
      case 'orogel':
        return <Image style={{ maxHeight: "40px" }} src={orogelLogo} />;
      case 'hera':
        return <Image style={{ maxHeight: "40px" }} src={heraLogo} />;
      case 'famila':
        return <Image style={{ maxHeight: "40px" }} src={familaLogo} />;
      case 'gabs':
        return <Image style={{ maxHeight: "40px" }} src={gabsLogo} />;
      case 'illy':
        return <Image style={{ maxHeight: "45px", marginLeft: "10px" }} src={illyLogo}/>;
      case 'esselunga':
        return <Image style={{ maxHeight: "40px" }} src={esselungaLogo} />;
      case 'kwell':
        return <Image style={{ maxHeight: "40px", backgroundColor: "black", padding: "5px", borderRadius: "4px" }} src={kwellLogo}/>;
      case 'dompe':
        return <Image style={{ maxHeight: "40px" }} src={okitaskLogo} />;
      case 'tavernello':
        return <Image style={{ maxHeight: "45px", marginLeft: "10px" }} src={tavernelloLogo}/>;
      case 'uriach':
        return <Image style={{ maxHeight: "40px" }} src={laborestLogo} />;
      case 'moschino':
        return <Image style={{ maxHeight: "40px" }} src={moschinoLogo} />;
      case 'naturitalia':
        return <Image style={{ maxHeight: "45px", marginLeft: "10px" }} src={naturitaliaLogo}/>;
      case 'peroni':
        return <Image style={{ maxHeight: "40px" }} src={peroniLogo} />;
      case 'peronichallenge':
        return <Image style={{ maxHeight: "40px" }} src={peroniLogo} />;
      case 'tannico':
        return <Image style={{ maxHeight: "45px", marginLeft: "10px" }} src={tannicoLogo}/>;
      case 'terranova':
        return <Image style={{ maxHeight: "45px", marginLeft: "10px" }} src={terranovaLogo}/>;
      case 'vergnano':
        return <Image style={{ maxHeight: "45px", marginLeft: "10px" }} src={vergnanoLogo}/>;
      case 'duetigli':
        return <Image style={{ maxHeight: "45px", marginLeft: "10px" }} src={duetigliLogo}/>;
      default:
        return <Header style={{ lineHeight: "40px", marginLeft: "10px" }}>{title}</Header>;
    } 
  }

  render() {
    return (
      <div className="App-header">
        <div
          style={{
            bottom: "5px",
            position: "absolute",
            maxWidth: "150px",
            height: "40px",
            top: "50%",
            left: "10px",
            transform: "translate(0, -50%)"
          }}
        >
          {this.renderLogo(this.props.title)}
        </div>
        {/*<Responsive minWidth={768} style={{textAlign:'center', lineHeight:"35px", position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)'}}>
					<sub>Benvenuto!</sub>
					<p className="App-title">QREVIEW - {this.props.title}</p>
				</Responsive>*/}
        <Link to="/" style={{ width: "57px", position: "absolute", top: "50%", right: "10px", transform: "translate(0, -50%)" }}>
          <Image id="logoImg" size="tiny" circular src={logo} />
        </Link>
      </div>
    );
  }
}

export default withRouter(CustomHeader);
