// import axios from 'axios';
// var qs = require('qs');

export function restaurantFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "RESTAURANT_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function hotelFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "HOTEL_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function productFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "PRODUCT_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function pearsonFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "PEARSON_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function shopFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "SHOP_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function wineFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "WINE_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function heraFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "HERA_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function gabsFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "GABS_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function illyFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "ILLY_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function esselungaFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "ESSELUNGA_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function kwellFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "KWELL_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function moschinoFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "MOSCHINO_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function naturitaliaFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "NATURITALIA_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function okitaskFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "OKITASK_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function peroniFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "PERONI_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function peroniChallengeFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "PERONI_CHALLENGE_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function tannicoFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "TANNICO_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function tavernelloFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "TAVERNELLO_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function terranovaFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "TERRANOVA_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function uriachFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "URIACH_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function vergnanoFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "VERGNANO_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function duetigliFlow(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "DUETIGLI_FLOW", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function popFromStack(phrase, caller) {
  let dtime = phrase.delay ? phrase.delay : 15;
  let time =
    phrase.response === "<IMG>" ? 1000 : phrase.response.length * dtime;
  return function(dispatch) {
    setTimeout(() => {
      dispatch({ type: "SET_DOTS" });
      setTimeout(() => {
        dispatch({ type: "UPDATE_CHAT_HISTORY" });
        dispatch({ type: "UNSET_DOTS" });
      }, time);
    }, Math.random() * 400);
  };
}
export function pushClientRequest(phrase, cb = null) {
  return function(dispatch) {
    dispatch({ type: "PUSH_CLIENT_REQUEST", phrase: phrase });
    if (cb) cb(phrase);
  };
}
export function refreshState(cb = null) {
  return function(dispatch) {
    dispatch({ type: "REFRESH_STATE" });
    if (cb) cb();
  };
}

export function clearButtons(cb = null) {
  return function(dispatch) {
    dispatch({ type: "CLEAR_BUTTONS" });
    if (cb) cb();
  };
}

export function saveReview(idReview, data) {
  return function(dispatch) {
    dispatch({ type: "SAVE_REVIEW", idReview: idReview, data: data });
  };
}

export function saveQuestion(idReview, data) {
  return function(dispatch) {
    dispatch({ type: "SAVE_QUESTION", idReview: idReview, data: data });
  };
}

export function deleteQuestion(idReview, idQuestion) {
  return function(dispatch) {
    dispatch({
      type: "DELETE_QUESTION",
      idReview: idReview,
      idQuestion: idQuestion
    });
  };
}
