import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import * as actions from './actions/action.js';
import { Segment, Form, Button, Icon, TextArea, Dropdown } from 'semantic-ui-react'
import './App.scss';

const typeOptions = [
  { key: 1, text: 'Voto', value: 'vote' },
  { key: 2, text: 'Risposta', value: 'answer' },
]

function connectActions (dispatch) {
    return bindActionCreators(actions, dispatch)
}
function mapStateToProps (store) {
  return {
    reviews: store.reviews,
  };
}

class ReviewsPage extends Component {

	state = {
		reviewId: 1,
		name: "",
		url: "",
		errors: {},
		addingReview: false,
		add: false,
		newQuestion: {
			id: null,
			type: 'vote',
			reason: false,
			text: ''
		}
	}

	componentWillMount = () => {
		var tmp = [];
		this.props.reviews.map(rev => (
			tmp.push({key: rev.id, text:rev.name, value:rev.id})
		));
		var rev = this.props.reviews.find(rev => {return rev.id === this.state.reviewId})
		this.setState({
			revOption: tmp,
			id: rev.id,
			name: rev.name,
			url: rev.url,
		});
	}

	componentWillReceiveProps = (nextProps) => {
		if (this.props.reviews.length!==nextProps.reviews.length) {
			var tmp = [];
			nextProps.reviews.map(rev => (
				tmp.push({key: rev.id, text:rev.name, value:rev.id})
			));
			this.setState({revOption: tmp});
		}
	}

	handleChangeReview = (e, {name, value}) => {
		if (this.state.reviewId !== value) {
			var rev = this.props.reviews.find(rev => {return rev.id === value})
			this.setState({
				id: rev.id,
				name: rev.name,
				url: rev.url,
				[name]: value,
				add:false, newQuestion: {id:null, type: 'vote', reason: false, question: ''}
			});
		}
	}
	handleChange = (e, {name, value}) => {
		this.setState({[name]: value});
	}

	handleChangeNewQ = (e, {name, value}) => {
		var tmp = {...this.state.newQuestion};
		tmp[name] = value;
		this.setState({newQuestion: tmp});
	}

	handleChangeBoolean = (e, { name }) => {
		var tmp = {...this.state.newQuestion};
		tmp[name] = !tmp[name];
		this.setState({newQuestion: tmp});
	}

	addQ = () => {
		if(!this.state.add)
			this.setState({add:true, newQuestion: {id:null, type: 'vote', reason: false, question: ''}});	
	}

	save = (questionData) => {
		this.props.saveQuestion(this.state.reviewId, questionData);
		if (!questionData.id) {
			this.setState({add:false, newQuestion: {id:null, type: 'vote', reason: false, question: ''}});
		}
	}

	delete = (questionData) => {
		if (!questionData.id) {
			this.setState({add:false, newQuestion: {id:null, type: 'vote', reason: false, question: ''}});
		} else {
		this.props.deleteQuestion(this.state.reviewId, questionData.id);
		}
	}

	saveReview = () => {
		this.props.saveReview(this.state.reviewId, {id:this.state.id, name:this.state.name, url:this.state.url});
		this.setState({addingReview:false, add:false});
	}

	addReview = () => {
		var index = 0;
		this.props.reviews.map(rev => (
			index = rev.id>index ? rev.id : index
		));
		index++;
		this.setState({
			addingReview: true,
			reviewId: index,
			id: index,
			name: "",
			url: ""
		});
	}
	cancelAddReview = () => {
		var rev = this.props.reviews.find(rev => {return rev.id === 1})
		this.setState({
			reviewId: rev.id,
			id: rev.id,
			name: rev.name,
			url: rev.url,
			addingReview: false,
		});
	}

	render() {
		var selReview = this.props.reviews.find(rev => {return rev.id === this.state.reviewId});
	    return (
	    	<Segment>
	    		<Form error style={{width:'100%'}}>
	    			{selReview &&
	    				<Dropdown placeholder='State' name='reviewId' selection value={this.state.reviewId} options={this.state.revOption} onChange={this.handleChangeReview}/>
	    			}
					<div>
						<Form.Group widths='equal'>
							<Form.Input fluid name='url' label='Url' value={this.state.url} placeholder='Url questionario' onChange={this.handleChange}/>
							<Form.Input fluid name='name' label='Nome' value={this.state.name} placeholder='Nome questionario' onChange={this.handleChange}/>
						</Form.Group>
						{selReview && selReview.questions.map((q, index) => (
							<QuestionRow key={index} reviewId={this.state.reviewId} data={q} save={this.save} delete={this.delete}/>
						))}
					</div>
					{this.state.add &&
						<Form.Group widths='equal' style={{borderBottom:'1px solid grey', paddingBottom:'15px'}}>
							<Form.Field>
								<Form.Select fluid name='type' value={this.state.newQuestion.type} label='Tipo domanda' options={typeOptions} placeholder='Tipo' onChange={this.handleChangeNewQ}/>
								{this.state.newQuestion.type==="vote" &&
									<Form.Checkbox name='reason' value='reason' checked={this.state.newQuestion.reason} label='Chiedi motivazione per voto insufficiente' onChange={this.handleChangeBoolean}/>
								}
								{(this.state.newQuestion.type==="answer" || (this.state.newQuestion.type==="vote" && this.state.newQuestion.reason)) &&
									<Form.Field control={TextArea} name='text' value={this.state.newQuestion.text} label='Domanda' placeholder={this.state.newQuestion.type==="vote" ? 'Scrivi il quesito..' : 'Scrivi il quesito in risposta al voto negativo'} onChange={this.handleChangeNewQ}/>
								}
							</Form.Field>
							<Form.Field style={{textAlign:'center'}}>
								<Icon name='save' size='large' color={this.state.add ? 'red' : 'black'} onClick={()=>this.save(this.state.newQuestion)}/>
								<Icon name='trash alternate' size='large' onClick={()=>this.delete(this.state.newQuestion)}/>
							</Form.Field>
						</Form.Group>
					}

					{!this.state.add && !this.state.addingReview &&
						<Form.Group widths='equal'>
							<Form.Field>					
								<Button color="green" onClick={this.addQ}><Icon name="add"/>Aggiungi</Button>
							</Form.Field>
						</Form.Group>
					}
					<Form.Group>
						<div style={{margin:'auto', width:'fit-content'}}>			
							<Button color="blue" onClick={this.saveReview}><Icon name="save"/>Salva</Button>
							{this.state.addingReview ?
								<Button color="red" onClick={this.cancelAddReview}><Icon name="close"/>Annulla aggiunta</Button>
							:
								<Button color="teal" onClick={this.addReview}><Icon name="add square"/>Aggiungi questionario</Button>
							}
						</div>			
					</Form.Group>
				</Form>
	    	</Segment>
	    );
	}
}

class QuestionRow extends Component {

	state = {
		change: false,
		question: {
			id: null,
			type: 'vote',
			reason: false,
			text: ''
		}
	}

	componentWillMount = () => {
		this.setState({question: this.props.data});
	}

	componentWillReceiveProps = (nextProps) => {
		if (this.props.reviewId !== nextProps.reviewId) {
			this.setState({question: nextProps.data});
		}
	}

	handleChange = (e, {name, value}) => {
		var tmp = {...this.state.question};
		tmp[name] = value;
		this.setState({question: tmp, change:true});
	}

	handleChangeBoolean = (e, { name }) => {
		var tmp = {...this.state.question};
		tmp[name] = !tmp[name];
		this.setState({question: tmp, change:true});
	}

	save = () => {
		this.props.save(this.state.question);
		this.setState({change:false});
	}

	delete = () => {
		this.props.delete(this.state.question);
	}

	render() {
	    return (
	    	<Form.Group widths='equal' style={{borderBottom:'1px solid grey', paddingBottom:'15px'}}>
				<Form.Field>
					<Form.Select fluid name='type' value={this.state.question.type} label='Tipo domanda' options={typeOptions} placeholder='Tipo' onChange={this.handleChange}/>
					{this.state.question.type==="vote" &&
						<Form.Checkbox name='reason' value='reason' checked={this.state.question.reason} label='Chiedi motivazione per voto insufficiente' onChange={this.handleChangeBoolean}/>
					}
					{(this.state.question.type==="answer" || (this.state.question.type==="vote" && this.state.question.reason)) &&
						<Form.Field control={TextArea} name='text' value={this.state.question.text} label='Domanda' placeholder={this.state.question.type==="vote" ? 'Scrivi il quesito..' : 'Scrivi il quesito in risposta al voto negativo'}  onChange={this.handleChange}/>
					}
				</Form.Field>
				<Form.Field style={{textAlign:'center'}}>
					<Icon name='save' size='large' color={this.state.change ? 'red' : 'black'} onClick={this.save}/>
					<Icon name='trash alternate' size='large' onClick={this.delete}/>
				</Form.Field>
			</Form.Group>
	    )
	}
}

export default connect(mapStateToProps, connectActions)(ReviewsPage);