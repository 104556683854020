import React, { Component } from 'react';
import { Link, withRouter }  from 'react-router-dom'
import { Segment, Menu, Responsive, Icon, Divider } from 'semantic-ui-react'
import './App.scss';

class AdminTemplate extends Component {
	
	state = {
		open: false
	}

	openMenu = () => {
		this.setState({open:!this.state.open});
	}

	render() {
	    return (
	    	<div>
				<Responsive as={Segment} minWidth={Responsive.onlyComputer.minWidth} style={{width:'250px', position:'fixed', top:0, bottom:0, marginBottom:0, borderTopLeftRadius:0, borderBottomLeftRadius:0}}>
					<p>Ciao,<br/>Nome Utente</p>
					<Divider />
		    		<AdminMenu page={this.props.page}/>
				</Responsive>
	    		<div className='main-content'>
					<Responsive as={Segment} className='responsive-menu' maxWidth={Responsive.onlyTablet.maxWidth}>
						<div style={{cursor:'pointer', padding:'10px 0'}} onClick={this.openMenu}><p><Icon name='bars' size='large'/>Ciao, Nome Utente</p></div>
						{this.state.open &&
							<AdminMenu page={this.props.page}/>
						}
					</Responsive>
			    	{this.props.children}
	    		</div>
	    	</div>
	    );
	}
}

class AdminMenu extends Component {
	render() {
	    return (
	    	<Menu pointing secondary vertical>
				<Menu.Item as={Link} to="/dashboard" name='home' content='home' active={this.props.page === 'dashboard'} onClick={this.handleItemClick}/>
				<Menu.Item as={Link} to="/dashboard/reviews" name='reviews' content='questionari' active={this.props.page === 'reviews'} onClick={this.handleItemClick}/>
				<Menu.Item as={Link} to="/dashboard/qr-generator" name='qr-generator' content='generatore qr' active={this.props.page === 'qr-generator'} onClick={this.handleItemClick}/>
				<Divider />
				<Menu.Item as={Link} to="/" name='root' content='root' active={this.props.page === 'root'} onClick={this.handleItemClick}/>
			</Menu>
	    )
	}
}

export default withRouter(AdminTemplate);