// import { slashedDate } from '../utils.js';

const initialState = {
	keyboard: false, // this flag say if chat allows button/keyboard switch
	duetigliStep:0,
	esselungaStep:0,
	gabsStep:0,
	heraStep:0,
	hotelStep:0,
	illyStep:0,
	kwellStep:0,
	moschinoStep:0,
	naturitaliaStep:0,
	okitaskStep:0,
	pearsonStep:0,
	peroniStep:0,
	peroniChallengeStep:0,
	productStep:0,
	restaurantStep:0,
	shopStep:0,
	tannicoStep:0,
	tavernelloStep:0,
	terranovaStep:0,
	uriachStep:0,
	vergnanoStep:0,
	wineStep:0,
	typing:false,
	linkNumber: 1, // to know which link url show in case of multiple link
	reviewsData: [
		{
			id: 1,
			header: [
				{name: 'Recensione', value: 2500},
				{name: 'Tempo medio', value: 300.35},
				{name: 'Placeholder', value: 4000},
				{name: 'Placeholder', value: 2000},
				{name: 'Placeholder', value: 25},
			],
			customArea: [
				{name: 'Gennaio', q1: 4000, q2: 2400},
				{name: 'Febbraio', q1: 3000, q2: 1398},
				{name: 'Marzo', q1: 2000, q2: 6800},
				{name: 'Aprile', q1: 2780, q2: 3908},
				{name: 'Maggio', q1: 1890, q2: 4800},
				{name: 'Giugno', q1: 2390, q2: 3800},
				{name: 'Luglio', q1: 3490, q2: 1300},
				{name: 'Agosto', q1: 4650, q2: 4020},
				{name: 'Settembre', q1: 2750, q2: 3105},
				{name: 'Ottobre', q1: 1129, q2: 5100},
				{name: 'Novembre', q1: 3005, q2: 3985},
				{name: 'Dicembre', q1: 2015, q2: 3850},
			],
			percVotes: [
				{value: 24, label: 'Eccelente'},
				{value: 43, label: 'Molto buono'},
				{value: 5, label: 'Nella Media'},
				{value: 14, label: 'Scarso'},
				{value: 14, label: 'Pessimo'}
			],
			customPie: [
				{
					name: 'Esperienza', 
					data:  [{value: 1, percent:20, filler:"#eb4841"},
						    {value: 2, percent:30, filler:"#f48847"},
						    {value: 3, percent:25, filler:"#ffc84a"},
						    {value: 4, percent:15, filler:"#a6c44a"},
						    {value: 5, percent:10, filler:"#4bc24a"}]
				},
				{
					name: 'Cibo', 
					data:  [{value: 1, percent:5, filler:"#eb4841"},
						    {value: 2, percent:35, filler:"#f48847"},
						    {value: 3, percent:30, filler:"#ffc84a"},
						    {value: 4, percent:25, filler:"#a6c44a"},
						    {value: 5, percent:10, filler:"#4bc24a"}]
				},
				{
					name: 'Servizi', 
					data:  [{value: 1, percent:10, filler:"#eb4841"},
						    {value: 2, percent:34, filler:"#f48847"},
						    {value: 3, percent:29, filler:"#ffc84a"},
						    {value: 4, percent:17, filler:"#a6c44a"},
						    {value: 5, percent:5, filler:"#4bc24a"}]
				},
			],
			customRadar: [
				{ subject: 'IOS', A: 20, B: 40, fullMark: 100 },
			    { subject: 'Android', A: 20, B: 50, fullMark: 100 },
			    { subject: 'Blackberry', A: 30, B: 10, fullMark: 100 },
			    { subject: 'Symbian', A: 20, B: 5, fullMark: 100 },
			    { subject: 'Others', A: 30, B: 20, fullMark: 100 }
			],
			customBar: [
				{name: 'Lunedì', week: 389},
			    {name: 'Martedì', week: 156},
			    {name: 'Mercoledì', week: 200},
			    {name: 'Giovedì', week: 218},
			    {name: 'Venerdì', week: 189},
			    {name: 'Sabato', week: 239},
			    {name: 'Domenica', week: 349}
			],
			comments:[
				{header:"Q1: commento sull'esperienza generale", date:"2 ore fa", text:"esperienza deludente! il posto è molto trafficato e quindi molto rumoroso."},
				{header:"Q3: commento sul servizio", date:"ieri", text:"i cornetti sono arrivati freddi e le lenzuola erano sporche!"},
				{header:"Q3: commento sul servizio", date:"2 giorni fa", text:"l'inserviente ha rovesciato la colazione sul mio abito da lavoro e quella mattina avevo una riunione molto importante."}
			]
		},
		{
			id: 2,
			header: [
				{name: 'Recensione', value: 750},
				{name: 'Tempo medio', value: 250.12},
				{name: 'Placeholder', value: 3000},
				{name: 'Placeholder', value: 1000},
				{name: 'Placeholder', value: 46},
			],
			customArea: [
				{name: 'Gennaio', q1: 2929, q2: 2400},
				{name: 'Febbraio', q1: 6927, q2: 1398},
				{name: 'Marzo', q1: 2153, q2: 2000},
				{name: 'Aprile', q1: 7065, q2: 3908},
				{name: 'Maggio', q1: 2855, q2: 3000},
				{name: 'Giugno', q1: 351, q2: 3800},
				{name: 'Luglio', q1: 3394, q2: 2000},
				{name: 'Agosto', q1: 5434, q2: 4020},
				{name: 'Settembre', q1: 6351, q2: 3105},
				{name: 'Ottobre', q1: 7133, q2: 4000},
				{name: 'Novembre', q1: 7002, q2: 3985},
				{name: 'Dicembre', q1: 7333, q2: 3850},
			],
			percVotes: [
				{value: 37, label: 'Eccelente'},
				{value: 42, label: 'Molto buono'},
				{value: 8, label: 'Nella Media'},
				{value: 7, label: 'Scarso'},
				{value: 6, label: 'Pessimo'}
			],
			customPie: [
				{
					name: 'Esperienza', 
					data:  [{value: 1, percent:23, filler:"#eb4841"},
						    {value: 2, percent:33, filler:"#f48847"},
						    {value: 3, percent:22, filler:"#ffc84a"},
						    {value: 4, percent:15, filler:"#a6c44a"},
						    {value: 5, percent:7, filler:"#4bc24a"}]
				},
				{
					name: 'Cibo', 
					data:  [{value: 1, percent:10, filler:"#eb4841"},
						    {value: 2, percent:35, filler:"#f48847"},
						    {value: 3, percent:25, filler:"#ffc84a"},
						    {value: 4, percent:25, filler:"#a6c44a"},
						    {value: 5, percent:10, filler:"#4bc24a"}]
				},
				{
					name: 'Servizi', 
					data:  [{value: 1, percent:15, filler:"#eb4841"},
						    {value: 2, percent:29, filler:"#f48847"},
						    {value: 3, percent:26, filler:"#ffc84a"},
						    {value: 4, percent:14, filler:"#a6c44a"},
						    {value: 5, percent:5, filler:"#4bc24a"}]
				},
			],
			customRadar: [
				{ subject: 'IOS', A: 30, B: 40, fullMark: 100 },
			    { subject: 'Android', A: 40, B: 50, fullMark: 100 },
			    { subject: 'Blackberry', A: 15, B: 5, fullMark: 100 },
			    { subject: 'Symbian', A: 5, B: 5, fullMark: 100 },
			    { subject: 'Others', A: 10, B: 20, fullMark: 100 }
			],
			customBar: [
				{name: 'Lunedì', week: 100},
			    {name: 'Martedì', week: 170},
			    {name: 'Mercoledì', week: 155},
			    {name: 'Giovedì', week: 202},
			    {name: 'Venerdì', week: 250},
			    {name: 'Sabato', week: 255},
			    {name: 'Domenica', week: 349}
			],
			comments:[
				{header:"Q2: commento sui piatti", date:"1 ora fa", text:"Dopo il pranzo non sono stato molto bene. Devono essere state le cozze!"},
				{header:"Q1: commento sul servizio", date:"ieri", text:"Non ho mai incontrato cameriera più antipatica!"},
				{header:"Q4: commento sul prodotto", date:"4 giorni fa", text:"I fagiolini congelati non hanno un gran sapore!"}
			]
		},
		{
			id: 3,
			header: [
				{name: 'Recensione', value: 3500},
				{name: 'Tempo medio', value: 270.35},
				{name: 'Placeholder', value: 4500},
				{name: 'Placeholder', value: 2030},
				{name: 'Placeholder', value: 28},
			],
			customArea: [
				{name: 'Gennaio', q1: 4300, q2: 2400},
				{name: 'Febbraio', q1: 3050, q2: 1398},
				{name: 'Marzo', q1: 2500, q2: 6800},
				{name: 'Aprile', q1: 6780, q2: 3908},
				{name: 'Maggio', q1: 7890, q2: 7800},
				{name: 'Giugno', q1: 8390, q2: 3800},
				{name: 'Luglio', q1: 3890, q2: 1300},
				{name: 'Agosto', q1: 2650, q2: 6020},
				{name: 'Settembre', q1: 2750, q2: 3105},
				{name: 'Ottobre', q1: 2129, q2: 5100},
				{name: 'Novembre', q1: 2005, q2: 3985},
				{name: 'Dicembre', q1: 2015, q2: 3850},
			],
			percVotes: [
				{value: 15, label: 'Eccelente'},
				{value: 35, label: 'Molto buono'},
				{value: 22, label: 'Nella Media'},
				{value: 19, label: 'Scarso'},
				{value: 9, label: 'Pessimo'}
			],
			customPie: [
				{
					name: 'Esperienza', 
					data:  [{value: 1, percent:25, filler:"#eb4841"},
						    {value: 2, percent:25, filler:"#f48847"},
						    {value: 3, percent:25, filler:"#ffc84a"},
						    {value: 4, percent:15, filler:"#a6c44a"},
						    {value: 5, percent:10, filler:"#4bc24a"}]
				},
				{
					name: 'Cibo', 
					data:  [{value: 1, percent:10, filler:"#eb4841"},
						    {value: 2, percent:35, filler:"#f48847"},
						    {value: 3, percent:30, filler:"#ffc84a"},
						    {value: 4, percent:15, filler:"#a6c44a"},
						    {value: 5, percent:10, filler:"#4bc24a"}]
				},
				{
					name: 'Servizi', 
					data:  [{value: 1, percent:10, filler:"#eb4841"},
						    {value: 2, percent:24, filler:"#f48847"},
						    {value: 3, percent:39, filler:"#ffc84a"},
						    {value: 4, percent:17, filler:"#a6c44a"},
						    {value: 5, percent:5, filler:"#4bc24a"}]
				},
			],
			customRadar: [
				{ subject: 'IOS', A: 20, B: 40, fullMark: 100 },
			    { subject: 'Android', A: 20, B: 50, fullMark: 100 },
			    { subject: 'Blackberry', A: 30, B: 10, fullMark: 100 },
			    { subject: 'Symbian', A: 20, B: 5, fullMark: 100 },
			    { subject: 'Others', A: 30, B: 20, fullMark: 100 }
			],
			customBar: [
				{name: 'Lunedì', week: 189},
			    {name: 'Martedì', week: 356},
			    {name: 'Mercoledì', week: 200},
			    {name: 'Giovedì', week: 218},
			    {name: 'Venerdì', week: 189},
			    {name: 'Sabato', week: 339},
			    {name: 'Domenica', week: 549}
			],
			comments:[
				{header:"Q5: suggerimenti", date:"10 min. fa", text:"Dovreste studiare un apertura della confezione migliore."},
				{header:"Q4: commento sul prodotto", date:"4 giorni fa", text:"I fagiolini congelati non hanno un gran sapore!"},
				{header:"Q4: commento sul prodotto", date:"1 settimana fa", text:"Non credo riprenderò il pollo. Rapporto qualità prezzo deludente"},
			]
		},
	],
	reviews: [
		{
			id:1,
			name: 'Ristorante',
			url:'restaurant',
			questions:[
				{
					id:1,
					type: 'vote',
					reason: true,
					text: 'Ci dispiace non ti sia trovato bene, ti va di spiegarci in breve come potremmo migliorare il servizio per venire in contro alle sue esigenze?'
				},
				{
					id:2,
					type: 'answer',
					reason: false,
					text: 'Cosa potremmo fare per migliorare la sua esperienza come cliente da noi?'
				}
			]
		},
		{
			id:2,
			name: 'Hotel',
			url:'hotel',
			questions:[
				{
					id:1,
					type: 'vote',
					reason: true,
					text: 'Ci dispiace non ti sia trovato bene, ti va di spiegarci in breve come potremmo migliorare il servizio per venire in contro alle sue esigenze?'
				},
				{
					id:2,
					type: 'vote',
					reason: true,
					text: 'Ci dispiace non ti sia trovato bene, ti va di spiegarci in breve come potremmo migliorare il servizio per venire in contro alle sue esigenze?'
				},
			]
		}
	],
	chatHistory:[],
	chatStack:[],
	buttons:[],
	buttonsTemp:[],
	resultArray:[],
	userComments:[],
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case "SET_DOTS":
			return {
				...state,
				typing:true,
			}
		case "UNSET_DOTS":
			return {
				...state,
				typing:false,
			}
		case "UPDATE_CHAT_HISTORY":
		let cs = state.chatStack[0];
			return {
				...state,
				buttons:state.chatStack.length === 1?state.buttonsTemp:state.buttons,
				chatStack:state.chatStack.slice(1),
				chatHistory:state.chatHistory.concat(cs),
			}
		case "DUETIGLI_FLOW":
			return duetigliFunc(state, action.phrase);
		case "ESSELUNGA_FLOW":
			return esselungaFunc(state, action.phrase);
		case "GABS_FLOW":
			return gabsFunc(state, action.phrase);
		case "HERA_FLOW":
			return heraFunc(state, action.phrase);
		case "HOTEL_FLOW":
			return hotelFunc(state, action.phrase);
		case "ILLY_FLOW":
			return illyFunc(state, action.phrase);
		case "KWELL_FLOW":
			return kwellFunc(state, action.phrase);
		case "MOSCHINO_FLOW":
			return moschinoFunc(state, action.phrase);
		case "NATURITALIA_FLOW":
			return naturitaliaFunc(state, action.phrase);
		case "OKITASK_FLOW":
			return okitaskFunc(state, action.phrase);
		case "PEARSON_FLOW":
			return pearsonFunc(state, action.phrase);
		case "PERONI_FLOW":
			return peroniFunc(state, action.phrase);
		case "PERONI_CHALLENGE_FLOW":
			return peroniChallengeFunc(state, action.phrase);
		case "PRODUCT_FLOW":
			return productFunc(state, action.phrase);
		case "RESTAURANT_FLOW":
			return restaurantFunc(state, action.phrase);
		case "SHOP_FLOW":
			return shopFunc(state, action.phrase);
		case "TANNICO_FLOW":
			return tannicoFunc(state, action.phrase);
		case "TAVERNELLO_FLOW":
			return tavernelloFunc(state, action.phrase);
		case "TERRANOVA_FLOW":
			return terranovaFunc(state, action.phrase);
		case "URIACH_FLOW":
			return uriachFunc(state, action.phrase);
		case "VERGNANO_FLOW":
			return vergnanoFunc(state, action.phrase);
		case "WINE_FLOW":
			return wineFunc(state, action.phrase);
		case "PUSH_CLIENT_REQUEST":
			return {
				...state,
				chatHistory: state.chatHistory.concat({user:"client", response:action.phrase}),
				buttons:[]
			}
		case "REFRESH_STATE":
			return {
				...state,
				duetigliStep:0,
				esselungaStep:0,
				gabsStep:0,
				heraStep:0,
				hotelStep:0,
				illyStep:0,
				kwellStep:0,
				moschinoStep:0,
				naturitaliaStep:0,
				okitaskStep:0,
				pearsonStep:0,
				peroniStep:0,
				peroniChallengeStep:0,
				productStep:0,
				restaurantStep:0,
				shopStep:0,
				tannicoStep:0,
				tavernelloStep:0,
				terranovaStep:0,
				vergnanoStep:0,
				wineStep:0,
				chatHistory:[],
				buttonsTemp:[],
				buttons:[],
				resultArray:[],
				userComments:[],
			}
		case "CLEAR_BUTTONS":
			return {
				...state,
				buttonsTemp:[],
			}
		case "SAVE_REVIEW":
			var revs, counter, found=false;
			revs = state.reviews.map(rev =>{
				if(rev.id === action.idReview) {
					rev = Object.assign(rev, action.data);
					found = true;
				}
				return rev;
			});
			if(found === false) {
				var data = {...action.data};
				data.questions = [];
				revs = [...state.reviews, data];
			}
			return {
				...state,
				reviews: revs,
			}
		case "SAVE_QUESTION":
			found = false;
			revs = state.reviews.map(rev =>{
				if(rev.id === action.idReview) {
					counter=0;
					rev.questions = rev.questions.map(q=>{
						counter = q.id>counter ? q.id : counter;
						if(q.id === action.data.id){
							q = action.data;
							found = true;
						}
						return q;
					});
				}
				return rev;
			});
			if(found === false) {
				data = {...action.data};
				data.id = counter+1;
				revs = state.reviews.map(rev =>{
					if(rev.id === action.idReview)
						rev.questions = [...rev.questions, data];
					return rev;
				});
			}
			return {
				...state,
				reviews: revs,
			}
		case "DELETE_QUESTION":
			return {
				...state,
				reviews: state.reviews.map(rev =>{
					if(rev.id === action.idReview)
						rev.questions = rev.questions.filter(q=>q.id!==action.idQuestion);
					return rev;
				}),
			}
		default:
			return state;
	}
}

function elaborateFinal (results) {
	return  (results.reduce((base, val) => {
		return parseInt(val) + base;} , 0)/results.length) >= 2.5;
}
let optStackKwell=[];
function calculateOptionsKwell(){
	let arr = arr_diff(["segnala un problema","informazioni generali","lascia un’opinione","contattaci"], optStackKwell);
	if(arr.length>0){
		return arr;
	}
	else {
		optStackKwell=[];
		return ["segnala un problema","informazioni generali","lascia un’opinione","contattaci"];
	}
}
function kwellFunc (state, phrase) {
	// calculates nextStep and the response
	switch(state.kwellStep){
		case 0:
			// first bot phrase
			return {
				...state,
				kwellStep: 1,
				buttonsTemp:["segnala un problema","informazioni generali","lascia un’opinione","contattaci"],
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"Grazie per aver utilizzato la nostra XBAG da 6 Kg."},
					{user:"bot", response:"<IMG>"},
					{user:"bot", response:"Come possiamo aiutarti?"}
				]
			};
		case 1:
			optStackKwell.push(phrase);
			if(phrase === "segnala un problema"){
				return {
					...state,
					kwellStep: 2,
					buttonsTemp:[],
					chatStack:[
						{user:"bot", response:"Siamo spiacenti che tu stia riscontrando problemi con la XBAG."},
						{user:"bot", response:"Per favore, lasciaci una tua mail per contattarti:"},
					]
				};
			}
			else if(phrase === "informazioni generali"){
				return {
					...state,
					kwellStep: 1,
					buttonsTemp:calculateOptionsKwell(),
					chatStack:[
						{user:"bot", response:"Uno degli attrezzi più apprezzati per l'allenamento funzionale"},
						{user:"bot", response:"Il sacco appesantito consente l'esecuzione della maggior parte degli esercizi funzionali del percorso a tempo."},
					]
				};
			}
			else if(phrase === "lascia un’opinione"){
				return {
					...state,
					kwellStep: 4,
					buttonsTemp:["1","2","3","4","5"],
					chatStack:[
						{user:"bot", response:"Qual è la tua opinione complessiva sulla nostra XBAG (1 pessimo - 5 ottimo)"},
					]
				};
			}
			else if(phrase === "contattaci"){
				return {
					...state,
					kwellStep: 1,
					buttonsTemp:calculateOptionsKwell(),
					chatStack:[
						{user:"bot", response:"Informazioni di contatto"},
					]
				};
			}
			break;
		case 2:
			return {
				...state,
				kwellStep: 3,
				buttonsTemp:[],
				chatStack:[
					{user:"bot", response:"Grazie, scrivi di seguito la descrizione del tuo problema"},
				]
			};
		case 3:
			return {
				...state,
				kwellStep: 1,
				buttonsTemp:calculateOptionsKwell(),
				chatStack:[
					{user:"bot", response:"Grazie per averci contattato. Ti risponderemo al più presto"},
				]
			};
		case 4:
			return {
				...state,
				kwellStep: 1,
				buttonsTemp:calculateOptionsKwell(),
				chatStack:[
					{user:"bot", response:"Grazie, la tua opinione è molto importante per noi."},
				]
			};
		default:
			return {...state};
	}
}

function esselungaFunc (state, phrase) {
	// calculates nextStep and the response
	switch(state.esselungaStep){
		case -1:
			return {
				...state,
				esselungaStep: 1,
				buttonsTemp:["1","2","3","4","più di 4"],
				chatStack:[
					{user:"bot", response:"Esselunga ti dà il benvenuto.", delay:40},
					{user:"bot", response:"Teniamo molto alla tua opinione sul prodotto.", delay:40},
					{user:"bot", response:"Se ti va rispondi a quache domanda veloce.", delay:40},
					{user:"bot", response:"Non preoccuparti ci vuole un attimo.", delay:40},
					{user:"bot", response:"Quante persone siete in famiglia?", delay:40}
				]
			};

		case 0:
			// first bot phrase
			return {
				...state,
				esselungaStep: 1,
				buttonsTemp:["1","2","3","4","più di 4"],
				chatStack:[
					{user:"bot", response:phrase, delay:40},
					{user:"bot", response:"Teniamo molto alla tua opinione sul prodotto.", delay:40},
					{user:"bot", response:"Se ti va rispondi a quache domanda veloce.", delay:40},
					{user:"bot", response:"Non preoccuparti ci vuole un attimo.", delay:40},
					{user:"bot", response:"Quante persone siete in famiglia?", delay:40}
				]
			};
		case 1:
			return {
				...state,
				esselungaStep: 2,
				buttonsTemp:["si","no"],
				chatStack:[
					{user:"bot", response:"Trovi che la grammatura del prodotto sia sufficiente per tutta la famiglia?"},
				]
			};
		case 2:
			if(phrase === "no")
				return {
					...state,
					esselungaStep: 2,
					buttonsTemp:["300g","500g","più di 500g"],
					chatStack:[
						{user:"bot", response:"Vorresti che fosse:"},
					]
				};
			else
				return {
					...state,
					esselungaStep: 3,
					buttonsTemp:["si","no"],
					chatStack:[
						{user:"bot", response:"Trovi che il packaging sia facilmente riponibile in frigorifero?"},
					]
				};
		case 3:
			if(phrase === "no")
				return {
					...state,
					esselungaStep: 4,
					buttonsTemp:["vassoio","cassettina","sacchetto"],
					chatStack:[
						{user:"bot", response:"Preferiresti"},
					]
				};
			else if(phrase === "si")
				return {
					...state,
					esselungaStep: -1,
					buttonsTemp:[],
					chatStack:[
						{user:"bot", response:"Grazie mille per la tua collaborazione"},
					]
				};
		break;
		case 4:
			return {
				...state,
				esselungaStep: -1,
				buttonsTemp:[],
				chatStack:[
					{user:"bot", response:"Grazie mille per la tua collaborazione"},
				]
			};
		default:
			return {...state};
	}
}
function heraFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.heraStep;
	let buttons = ["1","2","3","4","5"];
	// calculates nextStep and the response
	switch(state.heraStep){
		case 0:
			// first bot phrase
			return {
				...state,
				heraStep: 1,
				buttonsTemp:["problema","opinione"],
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"Vuoi segnalare un problema o esprimere un’opinione?"}
				]
			};
		case 1:
			//register user response
			state.resultArray.push(phrase);
			if(phrase === "opinione"){
				response = "Come valuti in una scala da 1 a 5 quest'area di smaltimento rifiuti?";
				nextStep = 2;
			}
			else if(phrase === "problema"){
				response = "D’accordo, quale problema hai riscontrato?";
				nextStep = 5;
				buttons = ["mancato svuotamento cassonetti","rifiuti abbandonati","scarsa pulizia"];
			}
			break;
		case 2:
			//register user response
			state.resultArray.push(phrase);
			response = "Vuoi aggiungere qualcosa?";
			nextStep = 3;
			buttons = ["no grazie","si"];
			break;
		case 3:
			//register user response
			state.resultArray.push(phrase);
			if(phrase === "no grazie"){
				response = "D'accordo, grazie per averci fornito la tua opinione, per noi è molto importante.";
				nextStep = -1;
				buttons = [];
			}
			else if(phrase === "si"){				 
				response = "Ok, scrivi pure di seguito.";
				nextStep = 4;
				buttons = [];
			}
			break;
		case 4:
			//register user response
			state.resultArray.push(phrase);
			response = "Grazie mille per il tuo contributo.";
			nextStep = -1;
			buttons = [];
			break;
		case 5:
			//register user response
			state.resultArray.push(phrase);
			response = "Grazie per la tua segnalazione, interverremo al più presto per risolvere il problema.\r\nSe vuoi essere avvisato al completamento dell'intervento lasciaci un tuo recapito.";
			nextStep = 6;
			buttons = ["no grazie", "mail", "telefono"];
			break;
		case 6:
			//register user response
			state.resultArray.push(phrase);
			if(phrase === "no grazie"){
				response = "D'accordo, risolveremo il problema e non ti contatteremo ulteriormente. Grazie e buona giornata";
				nextStep = -1;
				buttons = [];
			} else {
				response = "Ok, inserisci di seguito il tuo recapito:";
				nextStep = 7;
				buttons = [];
			}
			break;
		case 7:
			//register user response
			state.resultArray.push(phrase);
			response = "Grazie, appena avremo risolto il problema riceverai una nostra conferma.";
			nextStep = -1;
			buttons = [];
			
			break;
		default:
			return {...state};
	}

	//return new state
	return {
		...state,
		heraStep: nextStep,
		buttonsTemp:buttons,
		chatStack:[{user:"bot", response:response}]
	};
}

function hotelFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.hotelStep;
	let buttons = ["1","2","3","4","5"];
	// calculates nextStep and the response
	switch(state.hotelStep){
		case 0:
			// first bot phrase
			return {
				...state,
				hotelStep: 1,
				buttonsTemp:buttons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"Come valuti in generale la tua esperienza da noi?"}
				]
			};
		case 1:
			if(phrase > 0 && phrase <= 2){
				response = "Ci dispiace che la tua esperienza non sia stata positiva, ti va di dirci il perché? (NO per proseguire)";
				nextStep = 2;
				buttons = ["No"];
				//register user response
				state.resultArray.push(phrase);
			}
			else if(phrase > 2 && phrase <= 5){
				response = "Come valuti le camere della nostra struttura?";
				nextStep = 3;
				//register user response
				state.resultArray.push(phrase);
			}
			else
				response = "Valuta le risposta con un numero da 1 a 5! \r\n Come valuti in generale la tua esperienza da noi?";

			return {
				...state,
				hotelStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 2:
			response = "Come valuti le camere della nostra struttura?"
			nextStep = 3;

			//register user comment
			state.userComments.push({label:"Q1", text:phrase});


			return {
				...state,
				hotelStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 3:
			if(phrase > 0 && phrase <= 2){
				response = "Ci dispiace che la tua esperienza non sia stata positiva, ti va di dirci il perché? (NO per proseguire)";
				nextStep = 4;
				buttons = ["No"];
				//register user response
				state.resultArray.push(phrase);
			}
			else if(phrase > 2 && phrase <= 5){
				response = "Come valuti lo staff della nostra struttura?";
				nextStep = 5;

				//register user response
				state.resultArray.push(phrase);
			}
			else
				response = "Valuta le risposta con un numero da 1 a 5! \r\n Come valuti le camere della nostra struttura?";
			return {
				...state,
				hotelStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};

		case 4:
			response = "Come valuti lo staff della nostra struttura?"
			nextStep = 5;

			//register user comment
			state.userComments.push({label:"Q2", text:phrase});

			return {
				...state,
				hotelStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};

		case 5:
			if(phrase > 0 && phrase <= 2){
				response = "Ci dispiace che la tua esperienza non sia stata positiva, ti va di dirci il perché? (NO per proseguire)";
				nextStep = 6;
				buttons = ["No"];
				//register user response
				state.resultArray.push(phrase);
			}
			else if(phrase > 2 && phrase <= 5){
				response = "Come valuti i servizi della nostra struttura?";
				nextStep = 7;
				//register user response
				state.resultArray.push(phrase);
			}
			else
				response = "Valuta le risposta con un numero da 1 a 5! \r\n Come valuti lo staff della nostra struttura?";

			return {
				...state,
				hotelStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};

		case 6:
			response = "Come valuti i servizi della nostra struttura?"
			nextStep = 7;

			//register user comment
			state.userComments.push({label:"Q3", text:phrase});
			return {
				...state,
				hotelStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 7:
			if(phrase > 0 && phrase <= 2){
				response = "Ci dispiace che la tua esperienza non sia stata positiva, ti va di dirci il perché? (NO per proseguire)";
				nextStep = 8;
				buttons = ["No"];
				//register user response
				state.resultArray.push(phrase);
			}
			else if(phrase > 2 && phrase <= 5){
				response = "Come valuti la pulizia della nostra struttura?";
				nextStep = 9;
				//register user response
				state.resultArray.push(phrase);
			}
			else
				response = "Valuta le risposta con un numero da 1 a 5! \r\n Come valuti i servizi della nostra struttura?";
			return {
				...state,
				hotelStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 8:
			response = "Come valuti la pulizia della nostra struttura?"
			nextStep = 9;

			//register user comment
			state.userComments.push({label:"Q4", text:phrase});
			return {
				...state,
				hotelStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 9:
			buttons = [];
			if(phrase > 0 && phrase <= 2){
				response = "Ci dispiace che la tua esperienza non sia stata positiva, ti va di dirci il perché? (NO per proseguire)";
				nextStep = 10;
				buttons = ["No"];
				//register user response
				state.resultArray.push(phrase);
			}
			else if(phrase > 2 && phrase <= 5){
				response = "Vuoi lasciarci qualche suggerimento ulteriore o una tua opinione in generale? (NO per proseguire)";
				nextStep = 11;
				buttons = ["No"];
				//register user response
				state.resultArray.push(phrase);
			}
			else
				response = "Valuta le risposta con un numero da 1 a 5! \r\n Come valuti la pulizia della nostra struttura?";
			return {
				...state,
				hotelStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 10:
			buttons = [];
			response = "Vuoi lasciarci qualche suggerimento ulteriore o una tua opinione in generale? (NO per proseguire)"
			nextStep = 11;
			buttons = ["No"];
			//register user comment
			state.userComments.push({label:"Q5", text:phrase});
			return {
				...state,
				hotelStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 11:
			let resp = [];
			if(elaborateFinal(state.resultArray)){
				resp.push(
					{
						user:"bot",
						response:"Siamo molto contenti che tu abbia passato dei bei momenti nella nostra struttura. Ci farebbe piacere se tu volessi condividere la tua esperienza anche su <LINK>TripAdvisor<LINK>. Hai ancora un minuto di tempo per farlo?",
					}
				);
			}			
			resp.push(
				{
					user:"bot",
					response:"Grazie mille per il tuo contributo, speriamo di rivederti presto.",
				}
			);

			//register user comment
			state.userComments.push({label:"User Comment", text:phrase});
			return {
				...state,
				hotelStep: 1,
				buttonsTemp:[],
				chatStack:resp
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		hotelStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function illyFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.illyStep;
	let startButtons = ["info prodotto", "promozioni", "acquista", "valuta", "gioca"];
	let buttons = [];
	let newStack = [];
	let cleanedPhrase = phrase;
	let keyboard = state.keyboard;
	// calculates nextStep and the response
	switch(state.illyStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				illyStep: 1,
				buttonsTemp:startButtons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"<IMG1>"},
					{user:"bot", response:"Cliccando i bottoni puoi scegliere quali informazioni visualizzare o quali azioni compiere"},
				]
			};
		case 1:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "info prodotto":
					newStack = [
						{user:"bot", response:"Da più di 80 anni, illy si dedica a perfezionare un unico blend di 9 origini di Arabica, per offrire al mondo il suo miglior caffè."},
						{user:"bot", response:"Grazie al processo di pressurizzazione, gli aromi del blend vengono custoditi all’interno del barattolo illy, dove si affinano ed esaltano nel tempo, conservando una fragranza permanente che, all’apertura, si sprigiona nell’aria come una perfetta e inebriante sinfonia."},
						{user:"bot", response:"Il caffè tostato classico è perfetto per  gli amanti di un gusto morbido e vellutato e ha un retrogusto dolce e note delicate di caramello, fiori d’arancio e gelsomino."},
						{user:"bot", response:"Puoi assaporare l’unico blend illy macinato moka in 3 diverse intensità di gusto: "},
						{user:"bot", response:"<LINK><1>CLASSICO<LINK> (fascia di colore rosso), se ami una tazzina dal gusto morbido e vellutato;"},
						{user:"bot", response:"<LINK><2>INTENSO<LINK> (fascia di colore borgogna), se preferisci un gusto pieno e corposo; "},
						{user:"bot", response:"<LINK><3>FORTE<LINK> (fascia di colore nero), se cerchi un gusto ricco e deciso."},
					];
					buttons = startButtons;
					nextStep = 1;
					break;
				case "promozioni":
					newStack = [
						{user:"bot", response:"Non perderti le nuove offerte sulla gamma di prodotti illy"},
						{user:"bot", response:"Il nostro shop online è a tua disposizione"},
					];
					buttons = ["acquista", "no grazie"];
					nextStep = 2;
					break;
				case "acquista":
					newStack = [
						{user:"bot", response:"Il nostro shop online è a tua disposizione"},
					];
					buttons = ["vai allo shop", "no grazie"];
					nextStep = 3;
					break;
				case "valuta":
					newStack = [
						{user:"bot", response:"Grazie per la tua disponibilità, la tua opinione è molto importante per noi."},
						{user:"bot", response:"Complessivamente che giudizio daresti al prodotto? (1 insufficiente - 5 ottimo)"},
					];
					buttons = ["1", "2", "3", "4", "5"];
					nextStep = 4;
					break;
				case "gioca":
					newStack = [
						{user:"bot", response:"Ti porremo di seguito tre domande sulla storia di caffè Illy. Rispondi correttamente alle domande e potrai partecipare all’estrazione del premio."},
					];
					buttons = startButtons;
					nextStep = 1;
					break;
				default:
					return {
						...state,
						illyStep: state.illyStep,
						buttonsTemp: startButtons,
						chatStack:[
							{user:"bot", response:"Mi scuso, non ho capito. Riscrivi nuovamente la tua richiesta."}
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				keyboard: keyboard,
				illyStep: nextStep,
				buttonsTemp: buttons,
				chatStack: newStack
			};
		case 2:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "acquista":
					return {
						...state,
						illyStep:1,
						chatStack: [
							{user:"bot", response:"<LINK><4>Clicca qui e acquista subito<LINK>"},
						],
						buttonsTemp: startButtons
					};
				case "no grazie":
					return {
						...state,
						illyStep:1,
						chatStack: [
							{user:"bot", response:"Ok!"},
						],
						buttonsTemp: startButtons
					};
				default:
					state.resultArray.push(phrase);
					return {
						...state,
						illyStep: 2,
						buttonsTemp:["acquista", "no grazie"],
						chatStack:[
							{user:"bot", response:"Se acquisti almeno 4 confezioni di Pack 100 Èspresso 1882, ottieni 15€  DI SCONTO + 15€ di buono sconto sul tuo prossimo acquisto"},
						]
					};
			}
		case 3:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "vai allo shop":
					return {
						...state,
						illyStep:1,
						chatStack: [
							{user:"bot", response:"<LINK><5>Clicca qui e acquista subito una confezione di Caffè Macinato Moka tostato CLASSICO<LINK>"},
						],
						buttonsTemp: startButtons
					};
				case "no grazie":
					return {
						...state,
						illyStep:1,
						chatStack: [
							{user:"bot", response:"Ok!"},
						],
						buttonsTemp: startButtons
					};
				default:
					state.resultArray.push(phrase);
					return {
						...state,
						illyStep: 3,
						buttonsTemp:["vai allo shop", "no grazie"],
						chatStack:[
							{user:"bot", response:"Il nostro shop online è a tua disposizione"},
						]
					};
			}
		case 4:
			return {
				...state,
				illyStep: 5,
				buttonsTemp:["si", "no"],
				chatStack:[
					{user:"bot", response:"Riesci a trovare il prodotto con facilità?!"}
				]
			};
		case 5:
			return {
				...state,
				illyStep: 6,
				buttonsTemp:["no", "supermercato", "negozio", "online"],
				chatStack:[
					{user:"bot", response:"Ti ricordi dove hai acquistato il prodotto?"}
				]
			};
		case 6:
			return {
				...state,
				illyStep: 7,
				buttonsTemp:["si", "no"],
				chatStack:[
					{user:"bot", response:"Hai una segnalazione o un consiglio che vorresti darci?"}
				]
			};
		case 7:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "si":
					return {
						...state,
						illyStep:8,
						chatStack: [
							{user:"bot", response:"Scrivi pure il tuo suggerimento e premi sul pulsante di invio"},
						],
						buttonsTemp: []
					};
				case "no":
					return {
						...state,
						illyStep:1,
						chatStack: [
							{user:"bot", response:"Ok, il questionario è terminato. Grazie per averci inviato la tua opinione."},
						],
						buttonsTemp: startButtons
					};
				default:
				break;
			}
			break;
		case 8:
			state.resultArray.push(phrase);
			return {
				...state,
				illyStep: 1,
				buttonsTemp: startButtons,
				chatStack:[
					{user:'bot', response:'Grazie molte, il questionario è terminato.'},
				]
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		keyboard: keyboard,
		illyStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

let optStackPearson=[];
function calculateOptionsPearson(){
	let arr = arr_diff(["informazioni generali","novità","digitale","sapevi che..","altro testo","fine"], optStackPearson);
	if(arr.length>0){
		return arr;
	}
	else {
		optStackPearson=[];
		return ["informazioni generali","novità","digitale","sapevi che..","altro testo","fine"];
	}
}
function pearsonFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.hotelStep;
	let buttons = ["1","2","3","4","5"];
	// calculates nextStep and the response
	switch(state.pearsonStep){
		case 0:
			// first bot phrase
			return {
				...state,
				pearsonStep: 1,
				buttonsTemp:["informazioni generali","novità","digitale","sapevi che..","altro testo","fine"],
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"Corso di Matematica per il secondo biennio dei licei scientifici (autori: Canepa e Gerace)."},
					{user:"bot", response:"<IMG>"},
					{user:"bot", response:"Cosa desidera fare?"}
				]
			};
		case 1:
			nextStep = 1;
			optStackPearson.push(phrase);
			if(phrase === "informazioni generali"){
				return {
					...state,
					pearsonStep: 1,
					buttonsTemp:calculateOptionsPearson(),
					chatStack:[{user:"bot", response:"Contenuto informativo generale sul testo in questione."}]
				};
			}
			else if(phrase === "novità") {
				return {
					...state,
					pearsonStep: 1,
					buttonsTemp:calculateOptionsPearson(),
					chatStack:[
						{user:"bot", response:"	Le novità della nuova edizione sono queste. Segue elenco delle novità e di aspetti da mettere in evidenza."},
					]
				};
			}
			else if(phrase === "digitale") {
				return {
					...state,
					pearsonStep: 1,
					buttonsTemp:calculateOptionsPearson(),
					chatStack:[
						{user:"bot", response:"Abbiamo una nuova edizione digitale che può essere visionata online al link …"},
						{user:"bot", response:"Seguono altre informazioni sulle edizioni digitali."},
					]
				};
			}
			else if(phrase === "sapevi che..") {
				return {
					...state,
					pearsonStep: 1,
					buttonsTemp:calculateOptionsPearson(),
					chatStack:[
						{user:"bot", response:"Contenuti di approfondimento e informazioni aggiuntive per l’insegnante."},
					]
				};
			}
			else if(phrase === "altro testo") {
				return {
					...state,
					pearsonStep: 2,
					buttonsTemp:["Umanistico","Scientifico"],
					chatStack:[
						{user:"bot", response:"Qual è il suo ambito di interesse?"},
					]
				};
			}
			else if(phrase === "fine") {
				return {
					...state,
					pearsonStep: 2,
					buttonsTemp:[],
					chatStack:[
						{user:"bot", response:"Pearson Italia la ringrazia per il tempo che ci ha dedicato."},
						{user:"bot", response:"Restiamo a sua disposizione tramite il nostro rappresentante di zona o ai recapiti che può trovare online su <LINK>https://it.pearson.com/<LINK>"},
					]
				};
			}

			return {
				...state,
				pearsonStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 2:
			response = "Quale materia?";
			nextStep = 3;
			if(phrase === "Umanistico")
				buttons = ["Italiano","Latino"];
			else
				buttons = ["Matematica","Scienze"];
			
			return {
				...state,
				pearsonStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};

		case 3:
			response = "In che scuola insegna?"
			nextStep = 4;
			buttons = ["Liceo Scientifico","Istituto Tecnico"];

			return {
				...state,
				pearsonStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 4:
			response = "Classe?"
			nextStep = 5;
			return {
				...state,
				pearsonStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 5:
			response = "In questa fase viene indicato all’insegnante il testo proposto collegato alla scelta effettuata."
			nextStep = 1;
			return {
				...state,
				pearsonStep: nextStep,
				buttonsTemp:calculateOptionsPearson(),
				chatStack:[{user:"bot", response:response}]
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		pearsonStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function restaurantFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.restaurantStep;
	let buttons = ["1","2","3","4","5"];
	// calculates nextStep and the response
	switch(state.restaurantStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				restaurantStep: 1,
				buttonsTemp:buttons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"Come valuti in generale la tua esperienza da noi?"}
				]
			};
		case 1:
			if(phrase > 0 && phrase <= 2){
				response = "Ci dispiace che la tua esperienza non sia stata positiva, ti va di dirci il perché? (NO per proseguire)";
				nextStep = 2;
				buttons = ["No"];
				//register user response
				state.resultArray.push(phrase);
			}
			else if(phrase > 2 && phrase <= 5){
				response = "Come valuti i piatti che hai mangiato?";
				nextStep = 3;
				//register user response
				state.resultArray.push(phrase);
			}
			else
				response = "Valuta le risposta con un numero da 1 a 5! \r\n Come valuti in generale la tua esperienza da noi?";

			return {
				...state,
				restaurantStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 2:
			response = "Come valuti i piatti che hai mangiato?"
			nextStep = 3;

			//register user comment
			state.userComments.push({label:"Q1", text:phrase});


			return {
				...state,
				restaurantStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 3:
			if(phrase > 0 && phrase <= 2){
				response = "Ci dispiace che la tua esperienza non sia stata positiva, ti va di dirci il perché? (NO per proseguire)";
				nextStep = 4;
				buttons = ["No"];
				//register user response
				state.resultArray.push(phrase);
			}
			else if(phrase > 2 && phrase <= 5){
				response = "Come è stato il servizio?";
				nextStep = 5;

				//register user response
				state.resultArray.push(phrase);
			}
			else
				response = "Valuta le risposta con un numero da 1 a 5! \r\n Come valuti i piatti che hai mangiato?";
			return {
				...state,
				restaurantStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};

		case 4:
			response = "Come è stato il servizio?"
			nextStep = 5;

			//register user comment
			state.userComments.push({label:"Q2", text:phrase});

			return {
				...state,
				restaurantStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};

		case 5:
			buttons = ["No"];
			if(phrase > 0 && phrase <= 2){
				response = "Ci dispiace che la tua esperienza non sia stata positiva, ti va di dirci il perché? (NO per proseguire)";
				nextStep = 6;
				//register user response
				state.resultArray.push(phrase);
			}
			else if(phrase > 2 && phrase <= 5){
				response = "Vuoi lasciarci qualche suggerimento ulteriore o una tua opinione in generale? (NO per proseguire)";
				nextStep = 7;
				//register user response
				state.resultArray.push(phrase);
			}
			else
				response = "Valuta le risposta con un numero da 1 a 5! \r\n Come è stato il servizio?";

			return {
				...state,
				restaurantStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};

		case 6:
			response = "Vuoi lasciarci qualche suggerimento ulteriore o una tua opinione in generale? (NO per proseguire)"
			nextStep = 7;
			buttons = ["No"];
			//register user comment
			state.userComments.push({label:"Q5", text:phrase});
			return {
				...state,
				restaurantStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 7:
			let resp = [];
			if(elaborateFinal(state.resultArray)){
				resp.push(
					{
						user:"bot",
						response:"Siamo molto contenti che tu abbia passato dei bei momenti nella nostra struttura. Ci farebbe piacere se tu volessi condividere la tua esperienza anche su <LINK>https://www.tripadvisor.it<LINK>. Hai ancora un minuto di tempo per farlo?",
					}
				);
			}			
			resp.push(
				{
					user:"bot",
					response:"Grazie mille per il tuo contributo, speriamo di rivederti presto.",
				}
			);

			//register user comment
			state.userComments.push({label:"User Comment", text:phrase});
			return {
				...state,
				restaurantStep: 1,
				buttonsTemp:[],
				chatStack:resp
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		restaurantStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function productFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.productStep;
	let buttons = ["1","2","3","4","5"];
	// calculates nextStep and the response
	switch(state.productStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				productStep: 1,
				buttonsTemp:buttons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"<IMG>"},
					{user:"bot", response:"In una scala da 1 a 5, quanto ti è piaciuta?"},
				]
			};
		case 1:
			nextStep = 2;
			if(phrase > 0 && phrase <= 3){
				response = "Ti va di dirci cosa non ti ha convinto?";
				buttons = ["sapore", "sapidità", "quantità", "prezzo"];
				//register user response
				state.resultArray.push(phrase);
			}
			else if(phrase > 3 && phrase <= 5){
				response = "Bene, siamo molto contenti che tu l'abbia apprezzata.\r\nIn una scala da 1 a 5, quanto ritieni adeguato il prezzo di acquisto?";
				//register user response
				state.resultArray.push(phrase);
			}
			else
				response = "Valuta le risposta con un numero da 1 a 5! \r\n Come valuti in generale la tua esperienza da noi?";

			return {
				...state,
				productStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 2:
			response = "Se vuoi darci qualche suggerimento puoi farlo scrivendolo qui";			
			nextStep = 3;
			buttons = ["No, grazie"];
			state.resultArray.push(phrase);
			//register user comment
			state.userComments.push({label:"Q1", text:phrase});


			return {
				...state,
				productStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 3:
			response = "Grazie per la tua opinione, se vuoi, a questo link puoi trovare un video che mostra l’attenzione che dedichiamo al processo di produzione <LINK>https://www.youtube.com/watch?v=Ej3tR-HAgMk<LINK>";
			return {
				...state,
				productStep: nextStep,
				buttonsTemp:[],
				chatStack:[{user:"bot", response:response}]
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		productStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function shopFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.shopStep;
	let buttons = ["1","2","3","4","5"];
	let cleanedPhrase = phrase;
	// calculates nextStep and the response
	switch(state.shopStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				shopStep: 1,
				buttonsTemp:buttons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"Come valuti nel complesso la tua esperienza di acquisto?"},
				]
			};
		case 1:
			nextStep = 2;
			response = "Come valuti il servizio che hai ricevuto dai nostri addetti?";
			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				shopStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 2:
			response = "Hai dovuto attendere molto prima di essere servito o di poter pagare?";			
			nextStep = 3;
			buttons = ["si","no"];
			state.resultArray.push(phrase);
			//register user comment
			state.userComments.push({label:"Q1", text:phrase});

			return {
				...state,
				shopStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 3:
			if(phrase === "si"){
				response = "All’incirca quanto tempo hai dovuto attendere?";
				nextStep = 4;
				buttons = ["10 min.","tra 10 e 20 min.", "più di 20 min."];
			} else {
				response = "Consiglieresti ad amici o conoscenti di fare acquisti presso il nostro punto vendita?";
				nextStep = 5;
				buttons = ["si","no"];
			}
			state.resultArray.push(phrase);
			return {
				...state,
				shopStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:response}]
			};
		case 4:
			response = "Consiglieresti ad amici o conoscenti di fare acquisti presso il nostro punto vendita?";
			nextStep = 5;
			state.resultArray.push(phrase);
			return {
				...state,
				shopStep: nextStep,
				buttonsTemp:["si","no"],
				chatStack:[{user:"bot", response:response}]
			};
		case 5:
			response = "Hai suggerimenti da darci?";
			nextStep = 6;
			state.resultArray.push(phrase);
			return {
				...state,
				shopStep: nextStep,
				buttonsTemp:['Si', "No, grazie"],
				chatStack:[{user:"bot", response:response}]
			};
		case 6:
			state.resultArray.push(phrase);
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			if(cleanedPhrase === "si"){
				response = "Scrivi qui sotto il tuo suggerimento";
				nextStep = 7;
				buttons = [];
			} else if(cleanedPhrase === 'no, grazie') {
				response = "Grazie! La tua opinione è molto importante per noi.\r\nA presto";
				nextStep = 7;
				buttons = [];
			}
			return {
				...state,
				shopStep: nextStep,
				buttonsTemp:[],
				chatStack:[{user:"bot", response:response}]
			};
		case 7:
			response = "Grazie! La tua opinione è molto importante per noi.\r\nA presto";
			nextStep = 7;
			state.resultArray.push(phrase);
			return {
				...state,
				shopStep: nextStep,
				buttonsTemp:[],
				chatStack:[{user:"bot", response:response}]
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		shopStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function gabsFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.gabsStep;
	let buttons = ["1","2","3","4","5"];
	// calculates nextStep and the response
	switch(state.gabsStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				gabsStep: 1,
				buttonsTemp:["Verifica autenticità","Ecosostenibilità","La sua opinione"],
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"<IMG1>"},
					{user:"bot", response:"La soddisfazione dei nostri clienti ci sta a cuore ed è per questa ragione che abbiamo deciso di offrirle alcuni servizi aggiuntivi."},
				]
			};
		case 1:
			if(phrase === "Verifica autenticità"){	
				return {
					...state,
					gabsStep: 1,
					buttonsTemp:["Ecosostenibilità","La sua opinione"],
					chatStack:[
						{user:"bot", response:"<IMG2>"},
						{user:"bot", response:"Attraverso i nostri sistemi anticontraffazione e le informazioni contenute nel microchip siamo in grado certificare l’autenticità del prodotto da lei acquistato.Possiamo inoltre confermare che il materiale utilizzato per la produzione del prodotto da lei acquistato proviene esclusivamente dall’Italia."}
					]
				};
			} else if(phrase === "Ecosostenibilità"){
				return {
					...state,
					gabsStep: 1,
					buttonsTemp:["Verifica autenticità","La sua opinione"],
					chatStack:[
						{user:"bot", response:"Oltre ai clienti, a noi sta a cuore anche la salute del nostro pianeta. E’ per questo che la nostra catena produttiva rispetta tutte le indicazioni previste nell’accordo di Parigi."},
						{user:"bot", response:"A tal proposito le consigliamo la visione di questo video che vi illustrerà quanto noi siamo rispettosi della natura …"},
						{user:"bot", response:"<VIDEO>"},
					]
				};
			} else if(phrase === "La sua opinione"){
				return {
					...state,
					gabsStep: 2,
					buttonsTemp:["qualità","estetica","colori","funzionalità","durata"],
					chatStack:[
						{user:"bot", response:"Le chiediamo di dedicarci 1 minuto del suo tempo per rispondere ad alcune veloci domande."},
						{user:"bot", response:"Cosa l’ha spinta a scegliere un nostro prodotto?"},
					]
				};
			}
			break;
		case 2:
			// first bot phrase
			return {
				...state,
				gabsStep: 3,
				buttonsTemp:["outlet","negozio monomarca"],
				chatStack:[
					{user:"bot", response:"Dove ha acquistato il nostro prodotto?"},
				]
			};
		case 3:
			// first bot phrase
			return {
				...state,
				gabsStep: 4,
				buttonsTemp:["1","2","3","4","5"],
				chatStack:[
					{user:"bot", response:"Quanto è soddisfatta del suo acquisto?"},
				]
			};
		case 4:
			// first bot phrase
			return {
				...state,
				gabsStep: 5,
				buttonsTemp:["si","no"],
				chatStack:[
					{user:"bot", response:"Riacquisterebbe un nostro prodotto?"},
				]
			};
		case 5:
			if(phrase === "si"){	
				return {
					...state,
					gabsStep: 7,
					buttonsTemp:["si","no"],
					chatStack:[
						{user:"bot", response:"Grazie per la fiducia! Per noi è fondamentale che i nostri clienti siano contenti di aver acquistato un nostro prodotto!"},
						{user:"bot", response:"Ha qualche consiglio da darci?"},
					]
				};
			} else if(phrase === "no"){
				return {
					...state,
					gabsStep: 7,
					buttonsTemp:["si","no"],
					chatStack:[
						{user:"bot", response:"Desidera dirci come mai non riacquisterebbe un nostro prodotto?"},
					]
				};
			}
			break;
		case 6:
			return {
				...state,
				gabsStep: 7,
				buttonsTemp:["si","no"],
				chatStack:[
					{user:"bot", response:"Ha qualche consiglio da darci?"},
				]
			};
		case 7:
			if(phrase === "si"){	
				return {
					...state,
					gabsStep: 8,
					buttonsTemp:[],
					chatStack:[
						{user:"bot", response:"Grazie, può scrivere nel campo qui sotto e premere invia"},
					]
				};
			} else if(phrase === "no"){
				return {
					...state,
					gabsStep: 9,
					buttonsTemp:[],
					chatStack:[
						{user:"bot", response:"Le siamo grati per il tempo che ci ha dedicato."},
						{user:"bot", response:"Le consigliamo di tanto in tanto di scannerizzare nuovamente il suo prodotto. Troverà nuove informazioni ed offerte pensate esclusivamente per lei."},
					]
				};
			}
			break;
		case 8:
			return {
				...state,
				gabsStep: 9,
				buttonsTemp:[],
				chatStack:[
					{user:"bot", response:"Le siamo grati per il tempo che ci ha dedicato."},
					{user:"bot", response:"Le consigliamo di tanto in tanto di scannerizzare nuovamente il suo prodotto. Troverà nuove informazioni ed offerte pensate esclusivamente per lei."},
				]
			};
		default:
			return {...state};
	}

	//return new state
	return {
		...state,
		gabsStep: nextStep,
		buttonsTemp:buttons,
		chatStack:[{user:"bot", response:response}]
	};
}

let optStackWine=[];
function calculateOptionsWine(){
	let arr = arr_diff(["invia opinione", "acquista", "scopri di più"], optStackWine);
	if(arr.length>0){
		return arr;
	}
	else {
		optStackWine=[];
		return ["invia opinione", "acquista", "scopri di più"];
	}
}
function wineFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.shopStep;
	let buttons = ["1","2","3","4","5"];
	// calculates nextStep and the response
	switch(state.wineStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				wineStep: 1,
				buttonsTemp:["invia opinione", "acquista", "scopri di più"],
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"<IMG>"},
					{user:"bot", response:"Cosa preferisci fare?"},
				]
			};
		case 1:
			nextStep = 1;
			optStackWine.push(phrase);
			if(phrase === "invia opinione"){
				return {
					...state,
					wineStep: 2,
					buttonsTemp:buttons,
					chatStack:[{user:"bot", response:"Dai un voto da 1 a 5 al nostro prodotto (1 pessimo, 5 ottimo)"}]
				};
			}
			else if(phrase === "acquista") {
				return {
					...state,
					wineStep: 1,
					buttonsTemp:calculateOptionsWine(),
					chatStack:[
						{user:"bot", response:"Ci fa molto piacere. <LINK>Clicca qui<LINK> per collegarti direttamente al nostro e-commerce."},
						{user:"bot", response:"Ti ricordiamo che per ordini superiori ai 100 € la spedizione è gratuita!"},
						{user:"bot", response:"Vuoi fare altro?"}

					]
				};
			}
			else if(phrase === "scopri di più") {
				return {
					...state,
					wineStep: 1,
					buttonsTemp:calculateOptionsWine(),
					chatStack:[
						{user:"bot", delay:25, response:"Tauleto prende il nome da un’espressione usata dai contadini che da sempre lavorano sui nostri campi."},
						{user:"bot", delay:25, response:"In dialetto locale, con il termine Tauleto sono soliti indicare un terreno ricco di acqua;"},
						{user:"bot", delay:25, response:"non è quindi un caso che sotto ai vigneti di sangiovese del podere Tauleto scorrano due sorgenti che garantiscono la riduzione al minimo dello stress idrico delle piante."},
						{user:"bot", delay:25, response:"Tauleto è un vino dotato di grande carisma, il suo colore scuro tende a maestose sfumature viola."},
						{user:"bot", delay:25, response:"Note balsamiche iniziali che poi si trasformano in frutti maturi e dolcissimi."},
						{user:"bot", delay:25, response:"Il gusto è possente, lunghissimo sul finale con tanta polpa, intenso, morbido e tannico."},
						{user:"bot", delay:25, response:"90% Sangiovese Grosso, 10% Uva Longanesi."},
						{user:"bot", delay:25, response:"Perfetto se abbinato a carni rosse dalla cottura impegnativa e formaggi stagionati."},
						{user:"bot", response:"Vuoi fare altro?"}
					]
				};
			}
			break;
		case 2:
			return {
				...state,
				wineStep: 3,
				buttonsTemp:["ristorante","enoteca","online"],
				chatStack:[{user:"bot", response:"Dove lo hai acquistato?"}]
			};
		case 3:
			return {
				...state,
				wineStep: 4,
				buttonsTemp:buttons,
				chatStack:[{user:"bot", response:"Dai un voto da 1 a 5 al rapporto qualità prezzo (1 pessimo, 5 ottimo)"}]
			};
		case 4:
			return {
				...state,
				wineStep: 5,
				buttonsTemp:["uomo","donna"],
				chatStack:[{user:"bot", response:"Sei uomo o donna?"}]
			};
		case 5:
			return {
				...state,
				wineStep: 6,
				buttonsTemp:["18-29","30-39","40-49","50 - 59","60 o più"],
				chatStack:[{user:"bot", response:"Qual è la tua età? "}]
			};
		case 6:
			return {
				...state,
				wineStep: 7,
				buttonsTemp:["concludi"],
				chatStack:[{user:"bot", response:'Se vuoi lasciarci un commento scrivilo di seguito, altrimenti clicca “concludi”'}]
			};
		case 7:
			return {
				...state,
				wineStep: 1,
				buttonsTemp:calculateOptionsWine(),
				chatStack:[
					{user:"bot", response:"Grazie per averci inviato la tua opinione, per noi è molto importante. Per ringraziarti ti abbiamo messo a disposizione uno sconto del 10% per i tuoi acquisti sul nostro portale. Utilizza il codice “SCONTOQR” per ottenere lo sconto."},
					{user:"bot", response:"Vuoi fare altro?"}
				]
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		shopStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function moschinoFunc (state, phrase) {
	let response = 'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.';
	let nextStep = state.moschinoStep;
	let buttons = ['DICO LA MIA', 'OTTIENI SCONTO'];
	let newStack = [];
	let cleanedPhrase = phrase;
	// calculates nextStep and the response
	switch(state.moschinoStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				moschinoStep: 1,
				buttonsTemp: buttons,
				chatStack:[
					{user:'bot', response:phrase},
					{user:'bot', response:'Grazie per aver acquistato lo ZAINO CHINESE NEW YEAR.'},
					{user:'bot', response:'<IMG1>'},
					{user:'bot', response:'Teniamo molto alla tua opinione, per questo se vuoi puoi darci la tua opinione sul tuo ultimo acquisto oppure scoprire le promozioni che ti abbiamo dedicato.'},
				]
			};
		case 1:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case 'dico la mia':
					newStack = [
						{user:'bot', response:'Grazie per la tua disponibilità.'},
						{user:'bot', response:'Come valuteresti in generale la tua esperienza di acquisto sul nostro store online (1 pesssima - 5 ottima)?'},
					];
					buttons = ['1', '2', '3', '4', '5'];
					nextStep = 2;
					break;
				case 'ottieni sconto':
					newStack = [
						{user:'bot', response:'Dalle tue preferenze di acquisto pensiamo ti potrebbe interessare anche uno dei nostri accessori.'},
						{user:'bot', response:'Eccone alcuni', delay:40},
						{user:'bot', response:'<IMG2>', delay:120},
						{user:'bot', response:'<IMG3>', delay:120},
						{user:'bot', response:'<IMG4>', delay:120},
						{user:'bot', response:'In esclusiva per te abbiamo scelto di mettere a disposizione uno sconto del 10% su tutta la collezione accessori Moschino. Usa il codice MGN487 sul sito per ottenere la promo.'},
						{user:'bot', response:'Lo sconto è valido per 10 giorni a partire da oggi.', delay:20},
						{user:'bot', response:'<LINK>Clicca qui<LINK> per acquistare subito'},
					];
					nextStep = 1;
					break;
				default:
					return {
						...state,
						moschinoStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:'bot', response:'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.'},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				moschinoStep: nextStep,
				buttonsTemp: buttons,
				chatStack: newStack
			};
		case 2:
			nextStep = 1;
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case '1':
				case '2':
					return {
						...state,
						chatStack:[{user:"bot", response:'Cosa non è andato come ti aspettavi?'}],
						moschinoStep: 3,
						buttonsTemp:[],
					};
				case '3':
				case '4':
				case '5':
					newStack = [
						{user:'bot', response:'Lo zaino acquistato è per te o lo hai regalato?'},
					];
					buttons = ['PER ME', 'REGALO'];
					nextStep = 4;
					break;
				default:
					return {
						...state,
						moschinoStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:'bot', response:'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.'},
						]
					};
			}

			state.resultArray.push(phrase);
			return {
				...state,
				moschinoStep: nextStep,
				buttonsTemp: buttons,
				chatStack: newStack
			};
		case 3:
			return {
				...state,
				chatStack:[{user:"bot", response:'Lo zaino acquistato è per te o lo hai regalato?'}],
				moschinoStep: 4,
				buttonsTemp:['PER ME', 'REGALO'],
			};
		case 4:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case 'per me':
					return {
						...state,
						chatStack:[{user:"bot", response:'La qualità del prodotto ha soddisfatto le tue aspettative?'}],
						moschinoStep: 5,
						buttonsTemp:['SI', 'NO'],
					};
				case 'regalo':
					return {
						...state,
						chatStack:[{user:"bot", response:'Il regalo ha soddisfatto la persona che lo ha ricevuto?'}],
						moschinoStep: 6,
						buttonsTemp:['SI', 'NO'],
					};
				default:
					return {
						...state,
						moschinoStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:'bot', response:'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.'},
						]
					};
			}
		case 5:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case 'si':
					return {
						...state,
						chatStack:[{user:"bot", response:'Grazie per la tua disponibilità. Terremo in grande considerazione la tua segnalazione. A presto!'}],
						moschinoStep: 1,
						buttonsTemp:buttons,
					};
				case 'no':
					return {
						...state,
						chatStack:[{user:"bot", response:'Cosa non ti sembra all’altezza di ciò che ti aspettavi?'}],
						moschinoStep: 5,
						buttonsTemp:[],
					};
				default:
					return {
						...state,
						moschinoStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:'bot', response:'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.'},
						]
					};
			}
		case 6:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case 'si':
					return {
						...state,
						chatStack:[{user:"bot", response:'Grazie per la tua disponibilità. Terremo in grande considerazione la tua segnalazione. A presto!'}],
						moschinoStep: 1,
						buttonsTemp:buttons,
					};
				case 'no':
					return {
						...state,
						chatStack:[{user:"bot", response:'Cosa è andato storto?'}],
						moschinoStep: 7,
						buttonsTemp:[],
					};
				default:
					return {
						...state,
						moschinoStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:'bot', response:'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.'},
						]
					};
			}
		case 7:
			return {
				...state,
				chatStack:[{user:"bot", response:'Grazie per la tua disponibilità. Terremo in grande considerazione la tua segnalazione. A presto!'}],
				moschinoStep: 1,
				buttonsTemp:buttons,
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		moschinoStep: nextStep,
		chatStack:[{user:'bot', response:response}]
	};
}

function naturitaliaFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.naturitaliaStep;
	let startButtons = ["info prodotto", "promozioni", "acquista", "valuta", "gioca"];
	let buttons = [];
	let newStack = [];
	let cleanedPhrase = phrase;
	let keyboard = state.keyboard;
	// calculates nextStep and the response
	switch(state.naturitaliaStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				naturitaliaStep: 1,
				buttonsTemp:startButtons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"<IMG1>"},
					{user:"bot", response:"Cliccando i bottoni puoi scegliere quali informazioni visualizzare o quali azioni compiere"},
				]
			};
		case 1:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "info prodotto":
					newStack = [
						{user:"bot", response:"Il kiwi giallo è dolce, ha uno straordinario contenuto di fibre e vitamina C, è sodo e succoso con un gusto estremamente fresco che piace soprattutto ai bambini."},
						{user:"bot", response:"È bello da vedere, con un colore acceso che ricorda quello del sole e si conserva benissimo nel frigorifero di casa."},
						{user:"bot", response:"La polpa è giallo oro, chiara, morbida e soffice. Per non farli maturare troppo presto, tieni i kiwi nel frigo separati dagli altri tipi di frutta."},
						{user:"bot", response:"Nel rispetto dell’ambiente e l’amore per esso tutti i nostri kiwi sono coltivati con passione senza fertilizzanti, rispettando i rigidi protocolli in vigore e garantendo sempre una massima espressione di bontà."},
					];
					buttons = startButtons;
					nextStep = 1;
					break;
				case "promozioni":
					newStack = [
						{user:"bot", response:"Non perderti le nuove offerte sulla gamma di prodotti Jingold"},
						{user:"bot", response:"In tutti i punti vendita Conad ogni 2 confezioni di Kiwi Jongold potrai ricevere un simpatico ed utile omaggio."},
					];
					buttons = startButtons;
					nextStep = 1;
					break;
				case "acquista":
					newStack = [
						{user:"bot", response:"Il nostro shop online è a tua disposizione"},
					];
					buttons = ["vai allo shop", "no grazie"];
					nextStep = 2;
					break;
				case "valuta":
					newStack = [
						{user:"bot", response:"Grazie per la tua disponibilità, la tua opinione è molto importante per noi."},
						{user:"bot", response:"Complessivamente che giudizio daresti al prodotto? (1 insufficiente - 5 ottimo)"},
					];
					buttons = ["1", "2", "3", "4", "5"];
					nextStep = 3;
					break;
				case "gioca":
					newStack = [
						{user:"bot", response:"Ti porremo di seguito tre domande sui Kiwi Jingold, rispondi correttamente e potrai partecipare all’estrazione del premio."},
						{user:"bot", response:"Funzionalità demo, di seguito potrebbero comparire domande."},
					];
					buttons = startButtons;
					nextStep = 1;
					break;
				default:
					return {
						...state,
						naturitaliaStep: state.naturitaliaStep,
						buttonsTemp: startButtons,
						chatStack:[
							{user:"bot", response:"Mi scuso, non ho capito. Riscrivi nuovamente la tua richiesta."}
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				keyboard: keyboard,
				naturitaliaStep: nextStep,
				buttonsTemp: buttons,
				chatStack: newStack
			};
		case 2:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "vai allo shop":
					return {
						...state,
						naturitaliaStep:1,
						chatStack: [
							{user:"bot", response:"<LINK>Clicca qui e acquista subito i tuoi Kiwi Jingold<LINK>"},
						],
						buttonsTemp: startButtons
					};
				case "no grazie":
					return {
						...state,
						naturitaliaStep:1,
						chatStack: [
							{user:"bot", response:"Ok!"},
						],
						buttonsTemp: startButtons
					};
				default:
					state.resultArray.push(phrase);
					return {
						...state,
						naturitaliaStep: 3,
						buttonsTemp:["vai allo shop", "no grazie"],
						chatStack:[
							{user:"bot", response:"Il nostro shop online è a tua disposizione"},
						]
					};
			}
		case 3:
			return {
				...state,
				naturitaliaStep: 4,
				buttonsTemp:["si", "no"],
				chatStack:[
					{user:"bot", response:"Riesci a trovare il prodotto con facilità?!"}
				]
			};
		case 4:
			return {
				...state,
				naturitaliaStep: 5,
				buttonsTemp:["no", "supermercato", "negozio", "online"],
				chatStack:[
					{user:"bot", response:"Ti ricordi dove hai acquistato il prodotto?"}
				]
			};
		case 5:
			return {
				...state,
				naturitaliaStep: 6,
				buttonsTemp:["si", "no"],
				chatStack:[
					{user:"bot", response:"Hai una segnalazione o un consiglio che vorresti darci?"}
				]
			};
		case 6:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "si":
					return {
						...state,
						naturitaliaStep:7,
						chatStack: [
							{user:"bot", response:"Scrivi pure il tuo suggerimento e premi sul pulsante di invio"},
						],
						buttonsTemp: []
					};
				case "no":
					return {
						...state,
						naturitaliaStep:1,
						chatStack: [
							{user:"bot", response:"Ok, il questionario è terminato. Grazie per averci inviato la tua opinione."},
						],
						buttonsTemp: startButtons
					};
				default:
				break;
			}
			break;
		case 7:
			state.resultArray.push(phrase);
			return {
				...state,
				naturitaliaStep: 1,
				buttonsTemp: startButtons,
				chatStack:[
					{user:'bot', response:'Ok, il questionario è terminato. Grazie per averci inviato la tua opinione.'},
				]
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		keyboard: keyboard,
		naturitaliaStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function okitaskFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.okitaskStep;
	let buttons = ["dosi","indicazioni","acquisto","composizione","video","feedback"];
	let newStack = [];
	let cleanedPhrase = phrase;
	// calculates nextStep and the response
	switch(state.okitaskStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: true,
				okitaskStep: 1,
				buttonsTemp:buttons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"<IMG>"},
					{user:"bot", response:"Cliccando i bottoni può scegliere quali informazioni visualizzare o quali azioni compiere"},
				]
			};
		case 1:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "dosi":
					newStack = [
						{user:"bot", response:"Una sola volta, o ripetuta 2-3 volte al giorno, nelle forme dolorose di maggiore intensità."},
						{user:"bot", response:"È opportuno prendere il medicinale dopo i pasti."},
						{user:"bot", response:"Non impiegare per periodi di tempo prolungati senza il consiglio del medico."},
						{user:"bot", response:"Consultate il medico se il disturbo si presenta ripetutamente o se avete notato un qualsiasi cambiamento recente delle sue caratteristiche."},
					];
					break;
				case "indicazioni":
					newStack = [
						{user:"bot", response:"OKITASK 40 mg granulato può essere posto direttamente sulla lingua. Si dissolve con la saliva; questo ne consente l'impiego senza acqua."},
						{user:"bot", response:"È preferibile assumere il prodotto a stomaco pieno."},
						{user:"bot", response:"Non superare le dosi consigliate: in particolare i pazienti anziani dovrebbero attenersi ai dosaggi minimi sopraindicati."},
					];
					break;
				case "acquisto":
					newStack = [
						{user:"bot", response:"Può acquistare online il prodotto cliccando <LINK>qui<LINK>."},
					];
					break;
				case "composizione":
					newStack = [
						{user:"bot", response:"Una bustina contiene:"},
						{user:"bot", response:"Principio attivo: ketoprofene sale di lisina 40 mg (corrispondente a 25 mg di ketoprofene)"},
						{user:"bot", response:"Eccipienti: povidone, silice colloidale, idrossipropilmetilcellulosa, eudragit EPO, sodio dodecil solfato, acido stearico, magnesio stearato, aspartame, mannitolo, xilitolo, talco, aroma lime, aroma limone, aroma frescofort"},
					];
					break;
				case "video":
					newStack = [
						{user:"bot", response:"Può visionare la comunicazione associata al prodotto a questo link: <LINK>https://okitask.it/comunicazione<LINK>"},
					];
					break;
				case "posologia":
					newStack = [
						{user:"bot", response:"Una sola volta, o ripetuta 2-3 volte al giorno, nelle forme dolorose di maggiore intensità."},
					];
					break;
				case "controindicazioni":
					newStack = [
						{user:"bot", response:"Ipersensibilità al ketoprofene o a sostanze con analogo meccanismo d'azione (per esempio acido acetilsalicilico o altri FANS) o ad uno qualsiasi degli eccipienti."},
						{user:"bot", response:"Il prodotto non va somministrato nei soggetti nei quali l'impiego di altri farmaci antinfiammatori non steroidei abbia determinato reazioni di ipersensibilità come broncospasmo, attacchi d'asma, rinite acuta, orticaria, eruzioni cutanee. . Reazioni anafilattiche gravi, raramente fatali, sono state osservate in questi pazienti."},
					];
					break;
				case "prodotti simili":
					newStack = [
						{user:"bot", response:"Okitask è disponibile anche in compresse."},
						{user:"bot", response:"Clicca qui per maggiori informazioni: <LINK>https://okitask.it/okitask-compresse<LINK>"},
					];
					break;
				case "feedback":
					return {
						...state,
						okitaskStep: 2,
						buttonsTemp:[],
						chatStack:[
							{user:"bot", response:"Scriva nella casella di testo il suo feedback, la sua opinione è molto importante per noi."},
						]
					};
				default:
					return {
						...state,
						okitaskStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:"bot", response:"Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta."},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				okitaskStep: nextStep,
				buttonsTemp:buttons,
				chatStack:newStack
			};
		case 2:
			nextStep = 1;
			state.resultArray.push(phrase);
			return {
				...state,
				okitaskStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[
					{user:"bot", response:"Grazie molte per averci inviato la sua opinione."}
				]
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		okitaskStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function peroniFunc (state, phrase) {
	let response = 'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.';
	let nextStep = state.peroniStep;
	let buttons = ['info prodotti', 'profilo', 'formati', 'valuta', 'gioca'];
	let newStack = [];
	let cleanedPhrase = phrase;
	let welcomePhrase = 'Cliccando i bottoni puoi scegliere quali informazioni visualizzare o quali azioni compiere';
	// calculates nextStep and the response
	switch(state.peroniStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				peroniStep: 1,
				buttonsTemp:buttons,
				chatStack:[
					{user:'bot', response:phrase},
					{user:'bot', response:'<IMG1>'},
					{user:'bot', response:welcomePhrase},
				]
			};
		case 1:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case 'info prodotti':
					newStack = [
						{user:'bot', response:'La passione italiana? Ha il gusto Peroni.'},
						{user:'bot', response:'È la lager italiana di alta qualità che dal 1846 è sulle tavole degli italiani.'},
						{user:'bot', response:'Peroni viene prodotta oggi come allora, solo con ingredienti selezionati come il malto 100% italiano, frutto di una speciale qualità di orzo seguito con cura durante tutte le fasi della filiera.'},
						{user:'bot', response:'La qualità è una passione tutta italiana, Peroni anche.'},
					];
					break;
				case 'profilo':
					newStack = [
						{user:'bot', response:'Gradazione Alcolica: 4,7% vol.<BR>Tipo Di Birra: Lager<BR>Colore: Giallo paglierino<BR>Gusto: Moderatamente amaro, equilibrato di luppolo e malto.<BR>Fermentazione: Bassa<BR>Temperatura Di Servizio: 4-6° C<BR>Provenienza: Italia<BR>Tipo Bicchiere: Colonna biconica'},
					];
					break;
				case 'formati':
					newStack = [
						{user:'bot', response:'Birra Peroni è disponibile nei seguenti formati'},
						{user:'bot', response:'<IMG2>'},
						{user:'bot', response:'<IMG3>'},
					];
					nextStep = 1;
					break;
				case 'valuta':
					newStack = [
						{user:'bot', response:'Grazie per la tua disponibilità, la tua opinione è molto importante per noi.'},
						{user:'bot', response:'Complessivamente che giudizio daresti al prodotto? (1 insufficiente - 5 ottimo)'},
					];
					buttons = ['1', '2', '3', '4', '5'];
					nextStep = 2;
					break;
				case 'gioca':
					return {
						...state,
						peroniStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:'bot', response:'Ti porremo di seguito tre domande sulla storia della birra Peroni. Rispondi correttamente a tutte e tre le domande e potrai partecipare all’estrazione del premio.'},
						]
					};
				default:
					return {
						...state,
						peroniStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:'bot', response:'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.'},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				peroniStep: nextStep,
				buttonsTemp: buttons,
				chatStack: newStack
			};
		case 2: 
			state.resultArray.push(phrase);
			return {
				...state,
				peroniStep: 3,
				buttonsTemp:['si', 'no'],
				chatStack:[
					{user:'bot', response:'Riesci a trovare il prodotto con facilità?'}
				]
			};
		case 3:
			state.resultArray.push(phrase);
			return {
				...state,
				peroniStep: 4,
				buttonsTemp:['si', 'no'],
				chatStack:[
					{user:'bot', response:'Hai una segnalazione o un consiglio che vorresti darci?'}
				]
			};
		case 4:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case 'si':
					newStack = [
						{user:'bot', response:'Scrivi pure il tuo suggerimento e premi sul pulsante di invio'},
					];
					buttons = [];
					nextStep = 5;
				break;
				case 'no':
					newStack = [
						{user:'bot', response:'Ok, il questionario è terminato. Grazie per averci inviato la tua opinione.'},
					];
					nextStep = 1;
				break;
				default:
				break;
			}
			state.resultArray.push(phrase);
			
			return {
				...state,
				peroniStep: nextStep,
				buttonsTemp: buttons,
				chatStack: newStack
			};
		case 5:
			state.resultArray.push(phrase);
			return {
				...state,
				peroniStep: 1,
				buttonsTemp: buttons,
				chatStack:[
					{user:'bot', response:'Grazie molte, il questionario è terminato.'},
				]
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		peroniStep: nextStep,
		chatStack:[{user:'bot', response:response}]
	};
}

function peroniChallengeFunc (state, phrase) {
	let response = 'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.';
	let nextStep = state.peroniChallengeStep;
	let buttons = ['VAI AL CONCORSO', 'PIÙ INFO'];
	let newStack = [];
	let cleanedPhrase = phrase;
	let welcomePhrase = 'Fai la tua scelta utilizzando i bottoni';
	// calculates nextStep and the response
	switch(state.peroniChallengeStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				peroniChallengeStep: 1,
				buttonsTemp:buttons,
				chatStack:[
					{user:'bot', response:phrase},
					{user:'bot', response:'Peroni terzo tempo ti dà il benvenuto!', delay:40},
					{user:'bot', response:'Grazie per aver acquistato birra Peroni 3.5 con + gusto - alcol e - calorie!', delay:30},
					{user:'bot', response:'<IMG2>', delay:40},
					{user:'bot', response:'Il tuo acquisto ti dà diritto a partecipare al concorso!', delay:40},
					{user:'bot', response:'Ogni giorno puoi vincere un cartone Peroni da 24 bottiglie oppure il colorato mini-frigo Peroni.', delay:40},
					{user:'bot', response:'<IMG3>', delay:40},
					{user:'bot', response:welcomePhrase, delay:40},
				]
			};
		case 1:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case 'partecipa':
				case 'vai al concorso':
					nextStep = 2;
					buttons=['1730', '1846', '1903'];
					newStack = [
					    ...(cleanedPhrase==='vai al concorso' ? [{ user: 'bot', response: 'Ottima scelta! Iniziamo subito.' }] : []),
					    { user: 'bot', response: 'Per partecipare all’estrazione di oggi dovrai rispondere correttamente a tre domande su birra Peroni.' },
					    { user: 'bot', response: 'Ecco la prima: in quale anno Francesco Peroni ha inaugurato la prima fabbrica targata Peroni?' },
					];
					break;
				case 'più info':
					newStack = [
						{user:'bot', response:'In questo caso vengono mostrate maggiori informazioni sul concorso.'},
					];
					buttons=['PARTECIPA'];
					nextStep = 1;
					break;
				default:
					return {
						...state,
						peroniChallengeStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:'bot', response:'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.'},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				peroniChallengeStep: nextStep,
				buttonsTemp: buttons,
				chatStack: newStack
			};
		case 2:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case '1730':
				case '1903':
					nextStep = 2;
					newStack = [
						{user:'bot', response:'Siamo spiacenti la tua risposta non è corretta.'},
						{user:'bot', response:'Per partecipare all’estrazione è necessario rispondere correttamente a tutte e tre le domande. Ritenta, andrà certamente meglio!'},
					];
					buttons = ['1730', '1846', '1903'];
					break;
				case '1846':
					nextStep = 3;
					newStack = [
						{user:'bot', response:'Risposta esatta! Passiamo alla prossima domanda.'},
						{user:'bot', response:'In quale anno birra Peroni ha accompagnato le emozioni di un mondiale di calcio speciale?'},
					];
					buttons = ['1990', '2002', '2006'];
					break;
				default:
					return {
						...state,
						peroniChallengeStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:'bot', response:'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.'},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				peroniChallengeStep: nextStep,
				buttonsTemp: buttons,
				chatStack: newStack
			};
		case 3:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case '1990':
				case '2002':
					nextStep = 3;
					newStack = [
						{user:'bot', response:'Siamo spiacenti la tua risposta non è corretta.'},
						{user:'bot', response:'Per partecipare all’estrazione è necessario rispondere correttamente a tutte e tre le domande. Ritenta, andrà certamente meglio!'},
					];
					buttons = ['1990', '2002', '2006'];
					break;
				case '2006':
					nextStep = 4;
					newStack = [
						{user:'bot', response:'Risposta esatta! Passiamo all’ultima domanda.'},
						{user:'bot', response:'Che cos’è Campus Peroni?'},
						{user:'bot', response:'A. Un centro ricreativo per i dipendenti dell’azienda e le loro famiglie.'},
						{user:'bot', response:'B. Un centro d’eccellenza per la promozione e la diffusione della cultura della qualità e della sostenibilità in ambito agricolo e cerealicolo.'},
						{user:'bot', response:'C. Campus Peroni non esiste.'},
					];
					buttons = ['A', 'B', 'C'];
					break;
				default:
					return {
						...state,
						peroniChallengeStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:'bot', response:'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.'},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				peroniChallengeStep: nextStep,
				buttonsTemp: buttons,
				chatStack: newStack
			};
		case 4:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case 'a':
				case 'c':
					nextStep = 4;
					newStack = [
						{user:'bot', response:'Siamo spiacenti la tua risposta non è corretta.'},
						{user:'bot', response:'Per partecipare all’estrazione è necessario rispondere correttamente a tutte e tre le domande. Ritenta, andrà certamente meglio!'},
					];
					buttons = ['A', 'B', 'C'];
					break;
				case 'b':
					nextStep = 5;
					newStack = [
						{user:'bot', response:'Risposta esatta, complimenti! Hai risposto correttamente a tutte le domande e puoi partecipare all’estrazione del premio di oggi!'},
						{user:'bot', response:'Per partecipare all’estrazione dobbiamo chiederti i tuoi dati'},
						{user:'bot', response:'Prima di tutto abbiamo bisogno del tuoi consenso al trattamento dei dati personali, che saranno trattati con la massima cura.'},
					];
					buttons = ['acconsento', 'approfondisci'];
					break;
				default:
					return {
						...state,
						peroniChallengeStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:'bot', response:'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.'},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				peroniChallengeStep: nextStep,
				buttonsTemp: buttons,
				chatStack: newStack
			};
		case 5:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case 'acconsento':
					nextStep = 1;
					newStack = [
						{user:'bot', response:'la chat prosegue con la richiesta dei dati necessari. Registrandoli nel sistema.'},
					];
					break;
				case 'approfondisci':
					nextStep = 1;
					newStack = [
						{user:'bot', response:'viene proposto un link con le informazioni sul trattamento dei dati'},
					];
					break;
				default:
					return {
						...state,
						peroniChallengeStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:'bot', response:'Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.'},
						]
					};
			}

			state.resultArray.push(phrase);
			
			return {
				...state,
				peroniChallengeStep: nextStep,
				buttonsTemp: buttons,
				chatStack: newStack
			};

		default:
			break;
	}

	//return new state
	return {
		...state,
		peroniChallengeStep: nextStep,
		chatStack:[{user:'bot', response:response}]
	};
}

function tannicoFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.tannicoStep;
	let buttons = ["OK", "NO GRAZIE"];
	let newStack = [];
	let cleanedPhrase = phrase;
	// calculates nextStep and the response
	switch(state.tannicoStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				tannicoStep: 1,
				buttonsTemp:buttons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"Grazie per il tuo acquisto."},
					{user:"bot", response:"Per ottenere lo sconto a te dedicato rispondi a qualche domanda, basta un minuto."},
				]
			};
		case 1:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "ok":
					newStack = [
						{user:"bot", response:"Relativamente al tuo ultimo ordine, come valuti complessivamente la tua esperienza di acquisto sul nostro sito (1 pessimo - 5 ottimo)?"},
					];
					buttons = ["1", "2", "3", "4", "5"];
					nextStep = 2;
					break;
				case "no grazie":
					newStack = [
						{user:"bot", response:"D’accordo, se cambi idea leggi di nuovo il QRcode per chattare con noi! Un saluto da Tannico"},
					];
					buttons = ["Torna all'inizio"];
					nextStep = 7;
					break;
				default:
					return {
						...state,
						tannicoStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:"bot", response:"Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta."},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				tannicoStep: nextStep,
				buttonsTemp:buttons,
				chatStack:newStack
			};
		case 2:
			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				chatStack: [
					{user:"bot", response:"Come valuti il rapporto qualità prezzo che proponiamo (1 pessimo - 5 ottimo)?"},
				],
				buttonsTemp: ["1", "2", "3", "4", "5"],
				tannicoStep: 3,
			};
		case 3:
			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				chatStack: [
					{user:"bot", response:"Come valuti la varietà disponibile sul nostro store online (1 pessimo - 5 ottimo)?"},
				],
				buttonsTemp: ["1", "2", "3", "4", "5"],
				tannicoStep: 4,
			};
		case 4:
			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				chatStack: [
					{user:"bot", response:"Hai suggerimenti o segnalazioni che vorresti fare?"},
				],
				buttonsTemp: ["SI", "NO"],
				tannicoStep: 5,
			};
		case 5:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "si":
					newStack = [
						{user:"bot", response:"Grazie, la tua opinione è molto importante per noi. Scrivi la tua segnalazione di seguito."},
					];
					buttons = [];
					nextStep = 6;
					break;
				case "no":
					newStack = [
						{user:"bot", response:"Grazie per il tuo tempo. Il questionario è completato."},
						{user:"bot", response:"Puoi utilizzare il codice MN34GH per ottenere uno sconto del 5% sul tuo prossimo acquisto."},
						{user:"bot", response:"Il codice è valido fino al 31/07/2020"},
					];
					buttons = ["Torna all'inizio"];
					nextStep = 7;
					break;
				default:
					return {
						...state,
						tannicoStep: 5,
						buttonsTemp: ["SI", "NO"],
						chatStack:[
							{user:"bot", response:"Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta."},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				tannicoStep: nextStep,
				buttonsTemp:buttons,
				chatStack:newStack
			};
		case 6:
			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				chatStack: [
					{user:"bot", response:"Grazie per il tuo tempo. Il questionario è completato."},
					{user:"bot", response:"Puoi utilizzare il codice MN34GH per ottenere uno sconto del 5% sul tuo prossimo acquisto."},
					{user:"bot", response:"Il codice è valido fino al 31/07/2020"},
				],
				buttonsTemp: ["Torna all'inizio"],
				tannicoStep: 7,
			};
		case 7:
			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				chatStack: [
					{user:"bot", response:"Per ottenere lo sconto a te dedicato rispondi a qualche domanda, basta un minuto."},
				],
				buttonsTemp: buttons,
				tannicoStep: 1,
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		tannicoStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function tavernelloFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.tavernelloStep;
	let buttons = ["sostenibilità","altri prodotti","curiosità","video","feedback"];
	let newStack = [];
	let cleanedPhrase = phrase;
	// calculates nextStep and the response
	switch(state.tavernelloStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: true,
				tavernelloStep: 1,
				buttonsTemp:buttons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"<IMG1>"},
					{user:"bot", response:"Cliccando i bottoni qui sotto può scegliere quali informazioni visualizzare o quali azioni compiere"},
					{user:"bot", response:"In alternativa può scrivere liberamente ciò di cui ha bisogno nella casella di testo"},
				]
			};
		case 1:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "sostenibilità":
					newStack = [
						{user:"bot", response:"Tra i valori che, da sempre, ispirano le scelte del Gruppo Caviro ci sono tutela dell’ambiente, innovazione, massimo impiego delle risorse e impegno per una crescita sostenibile del territorio."},
						{user:"bot", response:"Questi principi hanno portato a un continuo miglioramento in termini produttivi e hanno reso il Gruppo Caviro un modello di economia circolare."},
					];
					break;
				case "altri prodotti":
					newStack = [
						{user:"bot", response:"<IMG2>"},
						{user:"bot", response:"<IMG3>"},
						{user:"bot", response:"<IMG4>"},
						{user:"bot", response:"<IMG5>"},
					];
					break;
				case "curiosità":
					newStack = [
						{user:"bot", response:"<LINK>https://youtu.be/r3NrzoOdZNk<LINK>"},
					];
					break;
				case "video":
					newStack = [
						{user:"bot", response:"<LINK>https://youtu.be/keARqcDA-Og<LINK>"},
					];
					break;
				case "feedback":
					return {
						...state,
						tavernelloStep: 2,
						buttonsTemp:[],
						chatStack:[
							{user:"bot", response:"Grazie per la sua collaborazione se desidera può lasciare un commento qua sotto"},
						]
					};
				case "acquisto":
					newStack = [
						{user:"bot", response:"Puoi acquistare i nostri prodotti online direttamente su <LINK>http://www.caviro.it<LINK>"}
					];
					break;
				case "grado alcolico":
					newStack = [
						{user:"bot", response:"I nostri vini hanno un grado alcolico compreso tra gli 11 ed i 13 gradi"}
					];
					break;
				case "prezzo consigliato":
					newStack = [
						{user:"bot", response:"Per il tavernello in confezione da un litro consigliamo un prezzo di 1,99 €"}
					];
					break;
				default:
					return {
						...state,
						tavernelloStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:"bot", response:"Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta."},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				tavernelloStep: nextStep,
				buttonsTemp:buttons,
				chatStack:newStack
			};
		case 2:
			nextStep = 1;
			state.resultArray.push(phrase);
			return {
				...state,
				tavernelloStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[
					{user:"bot", response:"La ringraziamo per la sua opinione, ne faremo tesoro."}
				]
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		tavernelloStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function terranovaFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.terranovaStep;
	let startButtons = ["OTTIMA","BUONA","SUFFICIENTE","SCARSA"];
	let buttons = [];
	let newStack = [];
	let cleanedPhrase = phrase;
	let keyboard = state.keyboard;
	// calculates nextStep and the response
	switch(state.terranovaStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: true,
				terranovaStep: 1,
				buttonsTemp:startButtons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"Come valuti complessivamente la tua esperienza di acquisto?"},
				]
			};
		case 1:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "ottima":
				case "buona":
				case "sufficiente":
					// keyboard = false;
					newStack = [
						{user:"bot", response:"Cosa ne pensi dell’assortimento di prodotti all’interno del punto vendita?"},
					];
					buttons = ["OTTIMO", "BUONO", "SUFFICIENTE", "SCARSO"];
					nextStep = 3;
					break;
				case "scarsa":
					newStack = [
						{user:"bot", response:"Siamo spiacenti, quale aspetto non ha soddisfatto maggiormente le tue aspettative?"},
					];
					buttons = ["ATTESA IN CASSA", "PERSONALE", "ASSORTIMENTO"];
					nextStep = 2;
					break;
				case "punti vendita":
						newStack = [
							{user:"bot", response:"A questo link puoi trovare la lista completa dei nostri punti vendita <LINK>https://store.terranovastyle.com/italia-7<LINK>"},
							{user:"bot", response:"Come valuti complessivamente la tua esperienza di acquisto?"},
						];
						buttons = startButtons;
						nextStep = 1;
						break;
				case "assistenza clienti":
						newStack = [
							{user:"bot", response:"Contattaci andando al link di seguito, il nostro supporto clienti ti fornirà l’assistenza di cui hai bisogno <LINK>https://terranovastyle.zendesk.com/hc/it/requests/new<LINK>"},
							{user:"bot", response:"Come valuti complessivamente la tua esperienza di acquisto?"},
						];
						buttons = startButtons;
						nextStep = 1;
						break;
				default:
					return {
						...state,
						terranovaStep: state.terranovaStep,
						buttonsTemp: startButtons,
						chatStack:[
							{user:"bot", response:"Mi scuso, non ho capito. Riscrivi nuovamente la tua richiesta."},
							{user:"bot", response:"Come valuti complessivamente la tua esperienza di acquisto?"},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				keyboard: keyboard,
				terranovaStep: nextStep,
				buttonsTemp: buttons,
				chatStack:newStack
			};
		case 2:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "punti vendita":
					return {
						...state,
						terranovaStep:1,
						chatStack: [
							{user:"bot", response:"A questo link puoi trovare la lista completa dei nostri punti vendita <LINK>https://store.terranovastyle.com/italia-7<LINK>"},
							{user:"bot", response:"Cosa ne pensi dell’assortimento di prodotti all’interno del punto vendita?"},
						],
						buttonsTemp: startButtons
					};
				case "assistenza clienti":
					return {
						...state,
						terranovaStep:1,
						chatStack: [
							{user:"bot", response:"Contattaci andando al link di seguito, il nostro supporto clienti ti fornirà l’assistenza di cui hai bisogno <LINK>https://terranovastyle.zendesk.com/hc/it/requests/new<LINK>"},
							{user:"bot", response:"Cosa ne pensi dell’assortimento di prodotti all’interno del punto vendita?"},
						],
						buttonsTemp: startButtons
					};
				default:
					state.resultArray.push(phrase);
					return {
						...state,
						terranovaStep: 3,
						buttonsTemp:["OTTIMO", "BUONO", "SUFFICIENTE", "SCARSO"],
						chatStack:[
							{user:"bot", response:"Cosa ne pensi dell’assortimento di prodotti all’interno del punto vendita?"},
						]
					};
			}
		case 3:
			state.resultArray.push(phrase);
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "punti vendita":
					return {
						...state,
						terranovaStep:1,
						chatStack: [
							{user:"bot", response:"A questo link puoi trovare la lista completa dei nostri punti vendita <LINK>https://store.terranovastyle.com/italia-7<LINK>"},
							{user:"bot", response:"Cosa ne pensi dell’assortimento di prodotti all’interno del punto vendita?"},
						],
						buttonsTemp: startButtons
					};
				case "assistenza clienti":
					return {
						...state,
						terranovaStep:1,
						chatStack: [
							{user:"bot", response:"Contattaci andando al link di seguito, il nostro supporto clienti ti fornirà l’assistenza di cui hai bisogno <LINK>https://terranovastyle.zendesk.com/hc/it/requests/new<LINK>"},
							{user:"bot", response:"Cosa ne pensi dell’assortimento di prodotti all’interno del punto vendita?"},
						],
						buttonsTemp: startButtons
					};
				default:
					state.resultArray.push(phrase);
					return {
						...state,
						terranovaStep: 4,
						buttonsTemp:[],
						chatStack:[
							{user:"bot", response:"Se vuoi lasciaci un tuo commento di seguito"},
						]
					};
			}
		case 4:
			state.resultArray.push(phrase);
			return {
				...state,
				terranovaStep: 1,
				buttonsTemp:[],
				chatStack:[
					{user:"bot", response:"Grazie per il tuo tempo, la tua opinione è molto importante per noi. A presto!"}
				]
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		keyboard: keyboard,
		terranovaStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function uriachFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.uriachStep;
	let buttons = ["INGREDIENTI", "USO", "ACQUISTO", "OPINIONE"];
	let newStack = [];
	let cleanedPhrase = phrase;
	// calculates nextStep and the response
	switch(state.uriachStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				uriachStep: 1,
				buttonsTemp:buttons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"<IMG>"},
					{user:"bot", response:"Quali informazioni desidera?"},
				]
			};
		case 1:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "ingredienti":
					newStack = [
						{user:"bot", response:"Tiobec® è un integratore alimentare a base di Acido α-Lipoico (acido tiottico)."},
						{user:"bot", response:"L’Acido α-Lipoico (Acido Tiottico o vitamina N) è un importante cofattore enzimatico all’interno del ciclo di Krebs, processo metabolico utilizzato dalla cellula per produrre ATP, molecola principalmente coinvolta nel metabolismo energetico del nostro organismo."},
						{user:"bot", response:"Quali altre informazioni desidera?"},
					];
					nextStep = 1;
					break;
				case "uso":
					newStack = [
						{user:"bot", response:"Si consiglia di assumere 1 compressa di TIOBEC® 800 al giorno."},
						{user:"bot", response:"Assumere la compressa almeno 30 minuti prima del pasto o 3 ore dopo (a stomaco vuoto)."},
						{user:"bot", response:"Tiobec® 800 può essere assunto anche da soggetti con intolleranza o sensibilità al glutine e al lattosio."},
						{user:"bot", response:"Quali altre informazioni desidera?"},
					];
					nextStep = 1;
					break;
				case "acquisto":
					newStack = [
						{user:"bot", response:"Tiobec® 800 può essere acquistato online a questo link <LINK>https://salustua.it/tiobec-fast-slow-800-20cpr-32g<LINK>"},
						{user:"bot", response:"Quali altre informazioni desidera?"},
					];
					nextStep = 1;
					break;
				case "opinione":
					newStack = [
						{user:"bot", response:"Grazie, la sua opinione è molto importante per noi."},
						{user:"bot", response:"Le faremo qualche domanda di seguito, per rispondere è sufficiente un minuto di tempo."},
						{user:"bot", response:"Chi le ha consigliato di assumere Tiobec® 800?"},
					];
					buttons = ["MEDICO", "CONOSCENTI", "ONLINE"];
					nextStep = 2;
					break;
				default:
					return {
						...state,
						uriachStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:"bot", response:"Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta."},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				uriachStep: nextStep,
				buttonsTemp:buttons,
				chatStack:newStack
			};
		case 2:
			return {
				...state,
				uriachStep: 3,
				chatStack:[
					{user:"bot", response:"Da quanto tempo assume Tiobec® 800?"},
				],
				buttonsTemp:["1-7 GIORNI", "2-3 SETTIMANE", "PIÙ DI 1 MESE"],
			};
		case 3:
			return {
				...state,
				uriachStep: 4,
				chatStack:[
					{user:"bot", response:"Le interesserebbe ricevere informazioni su altri integratori prodotti da Laborest?"},
				],
				buttonsTemp:["SI", "NO"],
			};
		case 4:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "si":
					return {
						...state,
						uriachStep: 5,
						chatStack:[
							{user:"bot", response:"Le invieremo con piacere il materiale informativo.<BR>Indichi di seguito la sua mail:"},
						],
						buttonsTemp:[],
					};
				case "no":
					return {
						...state,
						uriachStep: 6,
						chatStack:[
							{user:"bot", response:"La ringraziamo per il tempo che ci ha dedicato.<BR>Un cordiale saluto"},
						],
						buttonsTemp:["RICOMINCIA"],
					};
				default:
				break;
			}
			break;
		case 5:
			return {
				...state,
				uriachStep: 6,
				chatStack:[
					{user:"bot", response:"La ringraziamo per il suo interesse, a breve riceverà una nostra mail.<BR>Un cordiale saluto"},
				],
				buttonsTemp:["RICOMINCIA"],
			};
		case 6: 
			return {
				...state,
				uriachStep: 1,
				chatStack:[
					{user:"bot", response:"Quali informazioni desidera?"},
				],
				buttonsTemp:buttons,
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		uriachStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function vergnanoFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.vergnanoStep;
	let startButtons = ["info prodotto", "promozioni", "acquista", "valuta", "gioca"];
	let buttons = [];
	let newStack = [];
	let cleanedPhrase = phrase;
	let keyboard = state.keyboard;
	// calculates nextStep and the response
	switch(state.vergnanoStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: false,
				vergnanoStep: 1,
				buttonsTemp:startButtons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"<IMG1>"},
					{user:"bot", response:"Cliccando i bottoni puoi scegliere quali informazioni visualizzare o quali azioni compiere"},
				]
			};
		case 1:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "info prodotto":
					newStack = [
						{user:"bot", response:"La Lattina 100% Arabica 250gr. Caffè Vergnano è un prodotto di alta qualità, completamente made in Italy, che nasce dalle migliori varietà di Arabica, del centro America e dell'Africa."},
						{user:"bot", response:"Il caffè macinato che ne deriva è un prodotto equilibrato, dal gusto delicato e dall'aroma rotondo, che conferisce alla tazza un sapore unico e inconfondibile."},
						{user:"bot", response:"La Lattina 100% Arabica 250gr. Caffè Vergnano,  conservata e confezionata in atmosfera protettiva, conserva la propria fragranza e il proprio aroma grazie all'involucro che ne assicura la freschezza e ne migliora la conservazione. "},
					];
					buttons = startButtons;
					nextStep = 1;
					break;
				case "promozioni":
					newStack = [
						{user:"bot", response:"È arrivata l’estate! Fai scorta del tuo caffè preferito"},
						{user:"bot", response:"Se acquisti almeno 4 confezioni di Pack 100 Èspresso 1882, ottieni 15€  DI SCONTO + 15€ di buono sconto sul tuo prossimo acquisto"},
					];
					buttons = ["acquista", "no grazie"];
					nextStep = 2;
					break;
				case "acquista":
					newStack = [
						{user:"bot", response:"Il nostro shop online è a tua disposizione"},
					];
					buttons = ["vai allo shop", "no grazie"];
					nextStep = 3;
					break;
				case "valuta":
					newStack = [
						{user:"bot", response:"Grazie per la tua disponibilità, la tua opinione è molto importante per noi."},
						{user:"bot", response:"Complessivamente che giudizio daresti al prodotto? (1 insufficiente - 5 ottimo)"},
					];
					buttons = ["1", "2", "3", "4", "5"];
					nextStep = 4;
					break;
				case "gioca":
					newStack = [
						{user:"bot", response:"Ti porremo di seguito tre domande sulla storia di caffè Vergnano. Rispondi correttamente alle domande e potrai partecipare all’estrazione del premio."},
					];
					buttons = startButtons;
					nextStep = 1;
					break;
				default:
					return {
						...state,
						vergnanoStep: state.vergnanoStep,
						buttonsTemp: startButtons,
						chatStack:[
							{user:"bot", response:"Mi scuso, non ho capito. Riscrivi nuovamente la tua richiesta."}
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				keyboard: keyboard,
				vergnanoStep: nextStep,
				buttonsTemp: buttons,
				chatStack:newStack
			};
		case 2:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "acquista":
					return {
						...state,
						linkNumber: 1,
						vergnanoStep:1,
						chatStack: [
							{user:"bot", response:"<LINK>Clicca qui e acquista subito<LINK>"},
						],
						buttonsTemp: startButtons
					};
				case "no grazie":
					return {
						...state,
						vergnanoStep:1,
						chatStack: [
							{user:"bot", response:"Ok!"},
						],
						buttonsTemp: startButtons
					};
				default:
					state.resultArray.push(phrase);
					return {
						...state,
						vergnanoStep: 2,
						buttonsTemp:["acquista", "no grazie"],
						chatStack:[
							{user:"bot", response:"Se acquisti almeno 4 confezioni di Pack 100 Èspresso 1882, ottieni 15€  DI SCONTO + 15€ di buono sconto sul tuo prossimo acquisto"},
						]
					};
			}
		case 3:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "vai allo shop":
					return {
						...state,
						linkNumber: 2,
						vergnanoStep:1,
						chatStack: [
							{user:"bot", response:"<LINK>Clicca qui e acquista subito<LINK>"},
						],
						buttonsTemp: startButtons
					};
				case "no grazie":
					return {
						...state,
						vergnanoStep:1,
						chatStack: [
							{user:"bot", response:"Ok!"},
						],
						buttonsTemp: startButtons
					};
				default:
					state.resultArray.push(phrase);
					return {
						...state,
						vergnanoStep: 3,
						buttonsTemp:["vai allo shop", "no grazie"],
						chatStack:[
							{user:"bot", response:"Il nostro shop online è a tua disposizione"},
						]
					};
			}
		case 4:
			return {
				...state,
				vergnanoStep: 5,
				buttonsTemp:["si", "no"],
				chatStack:[
					{user:"bot", response:"Riesci a trovare il prodotto con facilità?!"}
				]
			};
		case 5:
			return {
				...state,
				vergnanoStep: 6,
				buttonsTemp:["no", "supermercato", "negozio", "online"],
				chatStack:[
					{user:"bot", response:"Ti ricordi dove hai acquistato il prodotto?"}
				]
			};
		case 6:
			return {
				...state,
				vergnanoStep: 7,
				buttonsTemp:["si", "no"],
				chatStack:[
					{user:"bot", response:"Hai una segnalazione o un consiglio che vorresti darci?"}
				]
			};
		case 7:
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "si":
					return {
						...state,
						vergnanoStep:8,
						chatStack: [
							{user:"bot", response:"Scrivi pure il tuo suggerimento e premi sul pulsante di invio"},
						],
						buttonsTemp: []
					};
				case "no":
					return {
						...state,
						vergnanoStep:1,
						chatStack: [
							{user:"bot", response:"Ok, il questionario è terminato. Grazie per averci inviato la tua opinione."},
						],
						buttonsTemp: startButtons
					};
				default:
				break;
			}
			break;
		case 8:
			state.resultArray.push(phrase);
			return {
				...state,
				vergnanoStep: 1,
				buttonsTemp: startButtons,
				chatStack:[
					{user:'bot', response:'Grazie molte, il questionario è terminato.'},
				]
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		keyboard: keyboard,
		vergnanoStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function duetigliFunc (state, phrase) {
	let response = "Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta.";
	let nextStep = state.duetigliStep;
	let buttons = ["sostenibilità","altri prodotti","video","feedback"];
	let newStack = [];
	let cleanedPhrase = phrase;

	switch(state.duetigliStep){
		case 0:
			// first bot phrase
			return {
				...state,
				keyboard: true,
				duetigliStep: 1,
				buttonsTemp:buttons,
				chatStack:[
					{user:"bot", response:phrase},
					{user:"bot", response:"<IMG1>"},
					{user:"bot", response:"Cliccando i bottoni qui sotto può scegliere quali informazioni visualizzare o quali azioni compiere"},
					{user:"bot", response:"In alternativa può scrivere liberamente ciò di cui ha bisogno nella casella di testo"},
				]
			};
		case 1:
			nextStep = 1;
			// clean phrase to avoid that posologia and Posologia are considered different words
			cleanedPhrase = cleanedPhrase.trim().toLowerCase();
			switch(cleanedPhrase){
				case "sostenibilità":
					newStack = [
						{user:"bot", response:"Tra i valori che, da sempre, ispirano le scelte del Gruppo Due Tigli ci sono tutela dell’ambiente, innovazione, massimo impiego delle risorse e impegno per una crescita sostenibile del territorio."},
						{user:"bot", response:"Questi principi hanno portato a un continuo miglioramento in termini produttivi e hanno reso il Gruppo Due Tigli un modello di economia circolare."},
					];
				break;
				case "altri prodotti":
					newStack = [
						{user:"bot", response:"<IMG2>"},
						{user:"bot", response:"<IMG3>"},
						{user:"bot", response:"<IMG4>"},
					];
					break;
				case "video":
					newStack = [
						{user:"bot", response:"<LINK>https://www.youtube.com/watch?v=K7Kbr5qEIZk<LINK>"},
					];
					break;
				case "feedback":
					return {
						...state,
						duetigliStep: 2,
						buttonsTemp:[],
						chatStack:[
							{user:"bot", response:"Grazie per la sua collaborazione se desidera può lasciare un commento qua sotto"},
						]
					};
				case "acquisto":
					newStack = [
						{user:"bot", response:"Puoi acquistare i nostri prodotti online direttamente su <LINK>https://www.terrecevico.com<LINK>"}
					];
					break;
				case "grado alcolico":
					newStack = [
						{user:"bot", response:"I nostri vini hanno un grado alcolico compreso tra gli 11 ed i 14 gradi"}
					];
					break;
				case "prezzo consigliato":
					newStack = [
						{user:"bot", response:"Per il brick Sancrispino in confezione da un litro consigliamo un prezzo di 1,67 €"}
					];
					break;
				default:
					return {
						...state,
						duetigliStep: 1,
						buttonsTemp:buttons,
						chatStack:[
							{user:"bot", response:"Mi scuso, non ho capito. La prego di scrivere nuovamente la sua richiesta."},
						]
					};
			}

			//register user response
			state.resultArray.push(phrase);
			
			return {
				...state,
				duetigliStep: nextStep,
				buttonsTemp: buttons,
				chatStack: newStack
			};
		case 2:
			nextStep = 1;
			state.resultArray.push(phrase);
			return {
				...state,
				duetigliStep: nextStep,
				buttonsTemp:buttons,
				chatStack:[
					{user:"bot", response:"La ringraziamo per la sua opinione, ne faremo tesoro."}
				]
			};
		default:
			break;
	}

	//return new state
	return {
		...state,
		duetigliStep: nextStep,
		chatStack:[{user:"bot", response:response}]
	};
}

function arr_diff (a1, a2) {

    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }

    for (var k in a) {
        diff.push(k);
    }

    return diff;
}