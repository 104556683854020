import React, { Component } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default class SimpleAreaChart extends Component{
  state = {
    width: 0
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth});
  }
	render () {
  	return (
      <ResponsiveContainer width='100%' height={400}>
        <AreaChart className='custom-area' data={this.props.data} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name"/>
          <YAxis/>
          <Tooltip/>
          <Area type='monotone' dataKey='q1' stroke='#8884d8' fill='#3caea3' />
          <Area type='monotone' dataKey='q2' stroke='#5b4b58' fill='#5b4b58' />
        </AreaChart>
      </ResponsiveContainer>
    )
  }
}