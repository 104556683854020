import React, { Component } from "react";
import { Segment, Image, Header, Input, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import arrow from "./images/arrow.png";
import conadQR from "./images/conad-512.png";
import craiQR from "./images/crai-512.png";
import dashboard from "./images/dashboard.png";
import dompeQR from "./images/dompe-512.png";
import esselungaQR from "./images/esselunga-512.png";
import fortehotelQR from "./images/fortehotel-512.png";
import familaQR from "./images/famila-512.png";
import gabsQR from "./images/gabs-512.png";
import heraQR from "./images/hera-512.png";
import hotelQR from "./images/hotel-512.png";
import illyQR from "./images/illy-512.png";
import kwellQR from "./images/kwell-512.png";
import laborestQR from "./images/laborest-512.png";
import moschinoQR from "./images/moschino-512.png";
import naturitaliaQR from "./images/naturitalia-512.png";
import orogelQR from "./images/orogel-512.png";
import pearsonQR from "./images/pearson-512.png";
import productQR from "./images/product-512.png";
import restaurantQR from "./images/restaurant-512.png";
import duetigliQR from "./images/duetigli-512.png";
import peroniQR from "./images/peroni-512.png";
import peroniChallengeQR from "./images/peroni-challenge-512.png";
import shopQR from "./images/shop-512.png";
import tannicoQR from "./images/tannico-512.png";
import tavernelloQR from "./images/tavernello-512.png";
import terranovaQR from "./images/terranova-512.png";
import vergnanoQR from "./images/vergnano-512.png";
import wineQR from "./images/wine-512.png";

export default class Home extends Component {
  state = {
    rowWidth: "",
    msg: "",
    rightSize: false
  };

  componentDidMount() {
    let el = document.getElementById("btnSubmit");
    if (el) {
      el.addEventListener("touchstart", this.changeWidth);
      el.addEventListener("click", this.changeWidth);
    }
  }

  changeWidth = event => {
    event.preventDefault();
    if (
      unescape("%36C%561%962%735%631%121".replace(/(%\d)/g, "%")) ===
      this.state.rowWidth
    ) {
      this.setState({ rightSize: true, msg: "" });
    } else {
      this.setState({ msg: "Password errata" })
    };
  };
  handleChange = (e, { value }) => {
    this.setState({ msg: "", rowWidth: value });
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.changeWidth(e);
    }
  };

  render() {
    return (
      <div>
        {this.state.rightSize ? (
          <div>
            <Segment id="homeContent" textAlign="left">
              <Link to="/dashboard">
                <h3 className="homeLink">
                  {" "}
                  <Image
                    src={dashboard}
                    style={{ display: "inline", width: "25px" }}
                  />{" "}
                  <span>Dashboard</span>{" "}
                </h3>
              </Link>
              <h3> Questionari: </h3>
              <div>
                <Link to="/poll/restaurant">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Ristorante{" "}
                  </h3>
                </Link>
                <a href={restaurantQR} download="restaurantQR.png" tabIndex="1">
                  <span> (qr-code) </span>
                </a>
              </div>
              <div>
                <Link to="/poll/hotel">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Hotel{" "}
                  </h3>
                </Link>
                <a href={hotelQR} download="hotelQR.png" tabIndex="2">
                  <span> (qr-code) </span>
                </a>
              </div>
              <div>
                <Link to="/poll/product">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Prodotto{" "}
                  </h3>
                </Link>
                <a href={productQR} download="productQR.png" tabIndex="3">
                  <span> (qr-code) </span>
                </a>
              </div>
              <div>
                <Link to="/poll/shop">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Negozio{" "}
                  </h3>
                </Link>
                <a
                  href={shopQR}
                  download="shopQR.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="/poll/conad">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Conad{" "}
                  </h3>
                </Link>
                <a
                  href={conadQR}
                  download="conadQR.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="/poll/orogel">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Orogel{" "}
                  </h3>
                </Link>
                <a
                  href={orogelQR}
                  download="orogelQR.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="/poll/crai">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Crai{" "}
                  </h3>
                </Link>
                <a
                  href={craiQR}
                  download="craiQR.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="/poll/fortehotel">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Forte Hotel{" "}
                  </h3>
                </Link>
                <a
                  href={fortehotelQR}
                  download="fortehotel.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="/poll/hera">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Hera{" "}
                  </h3>
                </Link>
                <a
                  href={heraQR}
                  download="hera.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="/poll/wine">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Wine{" "}
                  </h3>
                </Link>
                <a
                  href={wineQR}
                  download="wine.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="/poll/pearson">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Pearson{" "}
                  </h3>
                </Link>
                <a
                  href={pearsonQR}
                  download="pearson.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="poll/gabs">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Gabs{" "}
                  </h3>
                </Link>
                <a
                  href={gabsQR}
                  download="gabs.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="poll/kwell">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Kwell{" "}
                  </h3>
                </Link>
                <a
                  href={kwellQR}
                  download="kwell.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="poll/esselunga">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Esselunga{" "}
                  </h3>
                </Link>
                <a
                  href={esselungaQR}
                  download="esselunga.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="poll/dompe">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Dompe{" "}
                  </h3>
                </Link>
                <a
                  href={dompeQR}
                  download="dompe.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="poll/tavernello">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Tavernello{" "}
                  </h3>
                </Link>
                <a
                  href={tavernelloQR}
                  download="tavernello.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="poll/terranova">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Terranova{" "}
                  </h3>
                </Link>
                <a
                  href={terranovaQR}
                  download="terranova.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="poll/duetigli">
                  <h3 className="homeLink">
                    {" "}
                    <Image
                      src={arrow}
                      style={{
                        display: "inline",
                        width: "15px",
                        marginBottom: "3px"
                      }}
                    />{" "}
                    Duetigli{" "}
                  </h3>
                </Link>
                <a
                  href={duetigliQR}
                  download="duetigli.png"
                  tabIndex="4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="poll/peroni">
                  <h3 className="homeLink">
                    {" "}
                    <Image src={arrow} style={{ display: "inline", width: "15px", marginBottom: "3px"  }}/>
                    {" "}Peroni{" "}
                  </h3>
                </Link>
                <a href={peroniQR} download="peroni.png" tabIndex="4" target="_blank" rel="noopener noreferrer">
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="poll/peronichallenge">
                  <h3 className="homeLink">
                    {" "}
                    <Image src={arrow} style={{ display: "inline", width: "15px", marginBottom: "3px" }}/>
                    {" "}Peroni Challenge{" "}
                  </h3>
                </Link>
                <a href={peroniChallengeQR} download="peroni_challenge.png" tabIndex="4" target="_blank" rel="noopener noreferrer">
                  <span> (qr-code) </span>
                </a>
              </div>

              <div>
                <Link to="poll/vergnano">
                  <h3 className="homeLink">
                    {" "}
                    <Image src={arrow} style={{display: "inline", width: "15px", marginBottom: "3px"}}/>
                    {" "}Caffè Vergnano{" "}
                  </h3>
                </Link>
                <a href={vergnanoQR} download="vergnanoQR.png" tabIndex="4" target="_blank" rel="noopener noreferrer">
                  <span> (qr-code) </span>
                </a>
              </div>
              <div>
                <Link to="poll/moschino">
                  <h3 className="homeLink">
                    {" "}
                    <Image src={arrow} style={{display: "inline", width: "15px", marginBottom: "3px"}}/>
                    {" "}Moschino{" "}
                  </h3>
                </Link>
                <a href={moschinoQR} download="moschinoQR.png" tabIndex="4" target="_blank" rel="noopener noreferrer">
                  <span> (qr-code) </span>
                </a>
              </div>
              <div>
                <Link to="poll/tannico">
                  <h3 className="homeLink">
                    {" "}
                    <Image src={arrow} style={{display: "inline", width: "15px", marginBottom: "3px"}}/>
                    {" "}Tannico{" "}
                  </h3>
                </Link>
                <a href={tannicoQR} download="tannicoQR.png" tabIndex="4" target="_blank" rel="noopener noreferrer">
                  <span> (qr-code) </span>
                </a>
              </div>
              <div>
                <Link to="poll/uriach">
                  <h3 className="homeLink">
                    {" "}
                    <Image src={arrow} style={{display: "inline", width: "15px", marginBottom: "3px"}}/>
                    {" "}Uriach{" "}
                  </h3>
                </Link>
                <a href={laborestQR} download="laborestQR.png" tabIndex="4" target="_blank" rel="noopener noreferrer">
                  <span> (qr-code) </span>
                </a>
              </div>
              <div>
                <Link to="poll/famila">
                  <h3 className="homeLink">
                    {" "}
                    <Image src={arrow} style={{display: "inline", width: "15px", marginBottom: "3px"}}/>
                    {" "}Famila{" "}
                  </h3>
                </Link>
                <a href={familaQR} download="familaQR.png" tabIndex="4" target="_blank" rel="noopener noreferrer">
                  <span> (qr-code) </span>
                </a>
              </div>
              <div>
                <Link to="poll/illy">
                  <h3 className="homeLink">
                    {" "}
                    <Image src={arrow} style={{display: "inline", width: "15px", marginBottom: "3px"}}/>
                    {" "}Illy{" "}
                  </h3>
                </Link>
                <a href={illyQR} download="illyQR.png" tabIndex="4" target="_blank" rel="noopener noreferrer">
                  <span> (qr-code) </span>
                </a>
              </div>
              <div>
                <Link to="poll/naturitalia">
                  <h3 className="homeLink">
                    {" "}
                    <Image src={arrow} style={{display: "inline", width: "15px", marginBottom: "3px"}}/>
                    {" "}Naturitalia{" "}
                  </h3>
                </Link>
                <a href={naturitaliaQR} download="naturitaliaQR.png" tabIndex="4" target="_blank" rel="noopener noreferrer">
                  <span> (qr-code) </span>
                </a>
              </div>
            </Segment>
          </div>
        ) : (
          <div>
            <Segment id="homeContent" textAlign="center">
              <Header>
                <Icon name="warning sign" />
                Accesso privato
              </Header>
              <Input
                type="password"
                onKeyPress={this.handleKeyPress}
                onChange={this.handleChange}
              />
              <p style={{ color: "red" }}>{this.state.msg}</p>
              <Button id="btnSubmit" positive>
                {" "}
                Entra{" "}
              </Button>
            </Segment>
          </div>
        )}
      </div>
    );
  }
}
